export const projectIndustrialDirections: string[] = [
  "IT",
  "securityAndDefenseIndustry",
  "geologyGeodesyAndMeteorology",
  "stateAdministration",
  "other",
  "healthcareMedicinePharmaceuticals",
  "healthyLifestyleAndSports",
  "publicUtilities",
  "creativeTechnologiesAndTheEntertainmentIndustry",
  "cultureAndArt",
  "forestry",
  "mediaAndSocialNetworks",
  "metallurgyAndMiningIndustry",
  "theScience",
  "realty",
  "oilAndGasIndustry",
  "catering",
  "productionOfProducts",
  "connection",
  "agriculturalIndustry",
  "constructionAndInfrastructure",
  "tradeAndRetail",
  "transport",
  "tourismAndHotelBusiness",
  "finance",
  "chemicalIndustry",
  "notDefined",
  "energy",
];

export const projectTypes: string[] = [
  "softwareSolution",
  "hardwareAndSoftwareComplex",
  "initiativeOfTheRegion",
  "technology",
  "service",
  "notDefined",
  "hardwareSolution",
];

export const projectStages: string[] = ["ideaOrConcept", "prototypeOrMVP", "workingProduct", "scaling","notDefined"];

export const projectMarkets: string[] = [
  "AeroNet",
  "AutoNet",
  "EcoNet",
  "EduNet",
  "EnergyNet",
  "FoodNet",
  "GameNet",
  "HealthNet",
  "HomeNet",
  "MariNet",
  "MediaNet",
  "NeuroNet",
  "SafeNet",
  "SpaceNet",
  "SportNet",
  "TechNet",
  "WearNet", 
];

export const projectTechTypes: string[] = [
  "Prototyping3D",
  "additiveTechnologies",
  "bionics",
  "hydrogenTechnologies",
  "renewableMaterialsAndWasteRecycling",
  "genobionics",
  "geoinformationSystems",
  "greenEnergy",
  "artificialIntelligence",
  "quantumTechnologies",
  "collaborativeTechnologies",
  "molecularEngineering",
  "neurotechnologiesVirtualTechnologies",
  "newProductionTechnologies",
  "personalizedMedicine",
  "powerPlants",
  "syntheticBiology",
  "wirelessCommunicationTechnologies",
  "informationSecurityTechnologies",
  "quantumCommunicationTechnologies",
  "technologiesOfRoboticsAndMechatronics",
  "machineLearningAndCognitiveTechnologies",
  "technologiesOfModeling",
  "distributedRegistryTechnologies",
  "sensorTechnology",
  "technologiesForCreatingNewAndPortableEnergySources",
  "technologiesOfElectricPowerTransportation",
  "technologiesForControllingBiologicalObjects",
  "bigDataStorageAndAnalysisTechnologies",
  "carbonFootprintManagement",
  "photonics",
  "notDefined"
];

export const projectInvestmentsStages: string[] = [
  "governmentSubsidies",
  "ownInvestments",
  "angels",
  "preSeed",
  "seed",
  "stageA",
  "stageB",
  "stageC",
];

export const projectSalesTypes: string[] = ["noSales", "firstSales", "systemSales"];

export const projectBusinessModels: string[] = ["B2B", "B2B2C", "B2C", "B2G", "C2C", "G2B", "G2C" ];

export const projectMainGoals: string[] = [
  "biometricsTasks",
  "dataMiningTasks",
  "computerVisionTasks",
  "naturalLanguageProcessingTasks",
  "humanSpeechProcessingTasks",
  "tasksOfRecommendationSystems",
  "notDefined"
];
