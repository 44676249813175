import { Option } from "../../Components/ProfilePage/Modals/typings";

export const categoriesArray: string[] = [
  "Все",
  "business",
  "development",
  "design",
  "management",
  "analytic",
  "education",
  "content"
];
export const defaultOption = { value: "", label: "Не определен" };


export const categoriesOptions: Option[] = [
  // { value: 'all', label: 'Все' },
  { value: "business", label: "Бизнес" },
  { value: "development", label: "Разработка" },
  { value: "design", label: "Дизайн" },
  { value: "management", label: "Менеджмент" },
  { value: "analytic", label: "Аналитика" },
  { value: "education", label: "Образование" },
  { value: "content", label: "Контент" },
];

export const industrialDirectionsOptions: Option[] = [
  { label: "IT", value: "IT" },
  { label: "Безопасность и оборонная промышленность", value: "securityAndDefenseIndustry" },
  { label: "Геология, геодезия и метеорология", value: "geologyGeodesyAndMeteorology" },
  { label: "Гос. Управление", value: "stateAdministration" },
  { label: "Другое", value: "other" },
  { label: "Здравоохранение, медицина, фармацевтика", value: "healthcareMedicinePharmaceuticals" },
  { label: "ЗОЖ и спорт", value: "healthyLifestyleAndSports" },
  { label: "Коммунальное хозяйство", value: "publicUtilities" },
  { label: "Креативные технологии и индустрия развлечений", value: "creativeTechnologiesAndTheEntertainmentIndustry" },
  { label: "Культура и искусство", value: "cultureAndArt" },
  { label: "Лесное хозяйство", value: "forestry" },
  { label: "Медиа и соцсети", value: "mediaAndSocialNetworks" },
  { label: "Металлургия и добывающая промышленность", value: "metallurgyAndMiningIndustry" },
  { label: "Наука", value: "theScience" },
  { label: "Недвижимость", value: "realty" },
  { label: "Нефтегазовая отрасль", value: "oilAndGasIndustry" },
  { label: "Общепит", value: "catering" },
  { label: "Производство продуктов", value: "productionOfProducts" },
  { label: "Связь", value: "connection" },
  { label: "Сельское хозяйство", value: "agriculturalIndustry" },
  { label: "Строительство и инфраструктура", value: "constructionAndInfrastructure" },
  { label: "Торговля и ритейл", value: "tradeAndRetail" },
  { label: "Транспорт", value: "transport" },
  { label: "Туризм и гостиничный бизнес", value: "tourismAndHotelBusiness" },
  { label: "Финансы", value: "finance" },
  { label: "Химическая промышленность", value: "chemicalIndustry" },
  { label: "Энергетика", value: "energy" }
];

export const projectTypesOptions: Option[] = [
  {label:"Программное решение", value: "softwareSolution"},
  {label:"Программно-аппаратный комплекс", value: "hardwareAndSoftwareComplex"},
  {label:"Инициатива региона", value: "initiativeOfTheRegion"},
  {label:"Технология", value: "technology"},
  {label:"Услуга", value: "service"},
  {label:"Аппаратное решение", value: "hardwareSolution"},
];


export const projectStagesOptions: Option[] = [
  {label:"Идея или концепция", value: "ideaOrConcept"},
  {label:"Прототип или MVP", value: "prototypeOrMVP"},
  {label:"Работающий продукт", value: "workingProduct"},
  { label: "Масштабирование", value: "scaling" }
];

export const projectMarketsOptions: Option[] = [
 {label:"AeroNet",value:"AeroNet"} ,
 {label:"AutoNet",value:"AutoNet"} ,
 {label:"EcoNet",value:"EcoNet"} ,
 {label:"EduNet",value:"EduNet"} ,
 {label:"EnergyNet",value:"EnergyNet"} ,
 {label:"FoodNet",value:"FoodNet"} ,
 {label:"GameNet",value:"GameNet"} ,
 {label:"HealthNet",value:"HealthNet"} ,
 {label:"HomeNet",value:"HomeNet"} ,
 {label:"MariNet",value:"MariNet"} ,
 {label:"MediaNet",value:"MediaNet"} ,
 {label:"NeuroNet",value:"NeuroNet"} ,
 {label:"SafeNet",value:"SafeNet"} ,
 {label:"SpaceNet",value:"SpaceNet"} ,
 {label:"SportNet",value:"SportNet"} ,
 {label:"TechNet",value:"TechNet"} ,
 {label:"WearNet",value:"WearNet"} , 
];

export const projectTechTypesOptions: Option[] = [
  {label:"3Д прототипирование", value:"Prototyping3D"},
  {label:"Аддитивные технологии", value:"additiveTechnologies"},
  {label:"Бионика", value:"bionics"},
  {label:"Водородные технологии", value:"hydrogenTechnologies"},
  {label:"Возобновляемые материалы и переработка отходов", value:"renewableMaterialsAndWasteRecycling"},
  {label:"Генобионика", value:"genobionics"},
  {label:"Геоинформационные системы", value:"geoinformationSystems"},
  {label:"Зеленая энергетика", value:"greenEnergy"},
  {label:"Искусственный интеллект", value:"artificialIntelligence"},
  {label:"Квантовые технологии", value:"quantumTechnologies"},
  {label:"Коллаборативные технологии", value:"collaborativeTechnologies"},
  {label:"Молекулярный инжиниринг", value:"molecularEngineering"},
  {label:"Нейротехнологии, технологии виртуальной и дополненной реальностей", value:"neurotechnologiesVirtualTechnologies"},
  {label:"Новые производственные технологии", value:"newProductionTechnologies"},
  {label:"Персонифицированная медицина", value:"personalizedMedicine"},
  {label:"Силовые установки", value:"powerPlants"},
  {label:"Синтетическая биология", value:"syntheticBiology"},
  {label:"Технологии беспроводной связи и «интернета вещей»", value:"wirelessCommunicationTechnologies"},
  {label:"Технологии информационной безопасности", value:"informationSecurityTechnologies"},
  {label:"Технологии квантовой коммуникации", value:"quantumCommunicationTechnologies"},
  {label:"Технологии компонентов робототехники и мехатроники", value:"technologiesOfRoboticsAndMechatronics"},
  {label:"Технологии машинного обучения и когнитивные технологии", value:"machineLearningAndCognitiveTechnologies"},
  {label:"Технологии моделирования и разработки материалов с заданными свойствами", value:"technologiesOfModeling"},
  {label:"Технологии распределенных реестров", value:"distributedRegistryTechnologies"},
  {label:"Технологии сенсорики", value:"sensorTechnology"},
  {label:"Технологии создания новых и портативных источников энергии", value:"technologiesForCreatingNewAndPortableEnergySources"},
  {label:"Технологии транспортировки электроэнергии и распределённых интеллектуальных энергосистем", value:"technologiesOfElectricPowerTransportation"},
  {label:"Технологии управления свойствами биологических объектов", value:"technologiesForControllingBiologicalObjects"},
  {label:"Технологии хранения и анализа больших данных", value:"bigDataStorageAndAnalysisTechnologies"},
  {label:"Управление углеродным следом", value:"carbonFootprintManagement"},
  {label:"Фотоника", value:"photonics"},
];

export const projectInvestmentsStagesOptions: Option[] = [
  {label:"Государственные субсидии", value: "governmentSubsidies"},
  {label:"Собственные инвестиции", value: "ownInvestments"},
  {label:"Angels", value: "angels"},
  {label:"PreSeed", value: "preSeed"},
  {label:"Seed", value: "seed"},
  {label:"Stage A", value: "stageA"},
  {label:"Stage B", value: "stageB"},
  {label:"Stage C", value: "stageC"},
];

export const projectSalesTypesOptions: Option[] = [
  {label:"Нет продаж",value:"noSales"},
  {label:"Первые продажи",value:"firstSales"},
  {label:"Системные продажи",value:"systemSales"},
];

export const projectBusinessModelsOptions: Option[] = [
  {label:"B2B", value:"B2B"},
  {label:"B2B2C", value:"B2B2C"},
  {label:"B2C", value:"B2C"},
  {label:"B2G", value:"B2G"},
  {label:"C2C", value:"C2C"},
  {label:"G2B", value:"G2B"},
  {label:"G2C", value:"G2C"},
];

export const projectMainGoalsOptions: Option[] = [
  {label:"Задачи биометрии", value:"biometricsTasks"},
  {label:"Задачи интеллектуального анализа данных", value:"dataMiningTasks"},
  {label:"Задачи компьютерного зрения", value:"computerVisionTasks"},
  {label:"Задачи обработки естественного языка (NLP)", value:"naturalLanguageProcessingTasks"},
  {label:"Задачи обработки человеческой речи", value:"humanSpeechProcessingTasks"},
  {label:"Задачи рекомендательных систем", value:"tasksOfRecommendationSystems"},
];