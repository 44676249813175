import { makeStyles } from "@material-ui/core/styles";

// @ts-ignore
const useStyles = makeStyles(() => ({
  projectsFooterLink: {
    margin: "auto",
    padding: 60,
  },

  colorLink: {
    color: "#FF5631",
  },

  link: {
    color: "#fff",
  },
}));

export default useStyles;
