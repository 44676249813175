import { makeStyles } from "@material-ui/core/styles";
import { FS18, MobileFS20 } from "../../../../../../rules/index";

// @ts-ignore
const useStyles = makeStyles(() => ({
  modalContainer: {
    gap: 20,
    position: "relative",
    padding: "40px 60px 80px 60px",
    "@media (max-width: 899px)": {
      gap: 30,
      padding: "clamp(1.25rem, 5%, 2.5rem)",
    },
  },

  modalHeader: {
    color: "#252525",
    fontSize: 24,
    lineHeight: "120%",
    fontWeight: 500,
    textTransform: "uppercase",
    paddingBottom: 40,
    "@media (max-width:899px)": {
      paddingBottom: 20,
    },
  },

  modalHeaderText: {
    "@media (max-width:899px)": {
      color: "#AAADB2",
      fontSize: MobileFS20,
      lineHeight: "130%",
      fontWeight: 300,
    },
  },

  modalAcquiringText: {
    color: "#AAADB2",
    fontSize: 12,
    lineHeight: "130%",
    fontWeight: 300,
  },

  inputContainerGap: {
    gap: 30,
    "@media (max-width: 899px)": {
      gap: 15,
    },
  },

  articleImage: {
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },

  dateTime: {
    width: "100%",
    background:"none",
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
    overflow: "hidden",
    minWidth: "0%",
    borderColor: "rgba(0, 0, 0, 0.23)",
    padding: "8.5px 14px",


  },

  dateTimeFont: {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    color: "rgba(0, 0, 0, 0.87)",
  },

  imgConditionText: {
    color: "#AAADB2",
    fontSize: 16,
    lineHeight: "130%",
    fontWeight: 300,
  },

  additionalPartnerStyle: {
    color: "black",
    backgroundColor: "#fff",
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },

  modalButtonContainer: {
    marginTop: 40,
    paddingLeft: 30,
    gap: 20,
  },

  modalMobileButtonContainer: {
    marginTop: 30,
    gap: 20,
  },

  buttonContainerWeb: {
    display: "flex",

    flexDirection: "row-reverse",

    direction: "row-reverse",
    gap: 20,
    justifyContent: "flex-end",
    width: "100%",
    paddingLeft: "35%",
    marginTop: 30,
  },
  buttonContainerMobile: {
    marginTop: 30,
    gap: 20,
    direction: "column",
    paddingLeft: 30,
  },

  modalButton: {
    padding: "15px 15px",
    width: "100%",
  },
}));

export default useStyles;
