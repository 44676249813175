import { makeStyles } from "@material-ui/core/styles";

const useClasses = makeStyles(() => ({
  createGroupButton: {
    padding: "9px 27px",
    cursor: "pointer",
    borderRadius: 4,
    width: "100%",
    "@media (min-width: 900px)": {
      padding: "9px 27px",
    },
  },
}));

export default useClasses;
