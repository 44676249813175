import React, { useContext } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";

import { END_VCS_MUTATION } from "../../graphql/mutation";
import videoOff from "../../../../assets/icons/visualConference/cameraOff.svg";
import videoOn from "../../../../assets/icons/visualConference/cameraOn.svg";
import micOff from "../../../../assets/icons/visualConference/micOff.svg";
import micOn from "../../../../assets/icons/visualConference/micOn.svg";
import noAvatarIcon from "../../../../assets/img/SadCalendar.svg";
import { userContext } from "../../../../Context/context";
import { IGroupMember } from "../../typings";
import { LOCAL_VIDEO, useWebRTC } from "./hooks/useWebRTC";

const Wrapper = styled(Grid)(({ theme }) => ({
  "::-webkit-scrollbar": { width: "3px" },
  "::-webkit-scrollbar-track": {
    backgroundColor: theme.palette.background.default,
    borderRadius: "100px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.grey[800],
    borderRadius: "100px",
  },
  background: "#252525",
  overflow: "auto",
}));

const VideoBox = styled("div")({
  position: "relative",
  height: "215px",
  maxWidth: "400px",
  borderRadius: 8,
  margin: 10,
  overflow: "hidden",
});

interface VisualConferenceRoomProps {
  roomID: string;
  handleLeaveRoom: () => void;
  members: IGroupMember[];
}

const VisualConferenceRoom: React.FC<VisualConferenceRoomProps> = ({
  roomID,
  handleLeaveRoom,
  members,
}) => {
  const { user } = useContext(userContext);
  const {
    isVideoOn,
    isAudioOn,
    handleToggleVideo,
    handleToggleAudio,
    clients,
    provideMediaRef,
    mutedPeers,
    mapClientIdToUserId,
  } = useWebRTC(roomID, user.id.toString());

  console.log(clients, mapClientIdToUserId, members);

  console.log("clients");

  console.log(clients);

  const [endVCSChat] = useMutation(END_VCS_MUTATION, {
    variables: {
      data: {
        groupId: Number(roomID),
      },
    },
  });

  const handleEndVCS = async (): Promise<void> => {
    try {
      if (clients.length === 1) {
        await endVCSChat();
      }
      handleLeaveRoom();
    } catch (e) {
      handleLeaveRoom();
    }
  };

  return (
    <Wrapper container direction="column" wrap="nowrap">
      <Grid item xs>
        <Box
          display="grid"
          gridAutoFlow="row"
          gridTemplateColumns="repeat(auto-fit, minmax(300px, 400px))"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          {clients.map((clientID: string) => (
            <VideoBox key={clientID} id={clientID}>
              <video
                style={{ display: "block", width: "100%", height: "100%" }}
                ref={instance =>
                  instance && provideMediaRef(clientID, instance)
                }
                autoPlay
                playsInline
                muted={clientID === LOCAL_VIDEO}
              />
              {mutedPeers[clientID] && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  bgcolor="#343333"
                  width="100%"
                  height="100%"
                  left={0}
                  top={0}
                  zIndex={100}
                >
                  <Avatar
                    src={
                      members.find(m => m.id.toString() === clientID)?.avatar
                        ?.link || noAvatarIcon
                    }
                    sx={{
                      background: "grey",
                      p: 2,
                      width: 95,
                      height: 95,
                    }}
                  />
                  <Typography
                    color="#fff"
                    position="absolute"
                    left={20}
                    bottom={10}
                    fontSize={18}
                  >
                    {
                      members.find(m => m.id.toString() === clientID)
                        ?.firstname
                    }{" "}
                    {
                      members.find(m => m.id.toString() === clientID)
                        ?.lastname
                    }
                  </Typography>
                </Box>
              )}
            </VideoBox>
          ))}
        </Box>
      </Grid>
      <Box
        display="grid"
        gridTemplateColumns="repeat(3, fit-content(100%))"
        columnGap={2}
        justifyContent="center"
        paddingTop={2}
        paddingBottom={2}
        position="absolute"
        left={0}
        bottom={0}
        margin="0 auto"
        width="100%"
        bgcolor="#252525"
      >
        <IconButton onClick={handleToggleVideo} sx={{ borderRadius: "50%" }}>
          <img src={isVideoOn ? videoOn : videoOff} alt="Toggle Video" />
        </IconButton>
        <IconButton onClick={handleToggleAudio} sx={{ borderRadius: "50%" }}>
          <img src={isAudioOn ? micOn : micOff} alt="Toggle Audio" />
        </IconButton>
        <Button
          onClick={handleEndVCS}
          variant="contained"
          sx={{
            borderRadius: 4,
            backgroundColor: "#FF5631",
            "&:hover": { backgroundColor: "#e14f2b" },
          }}
        >
          <Typography color="#fff" textTransform="none">
            Выйти из конференции
          </Typography>
        </Button>
      </Box>
    </Wrapper>
  );
};

export default VisualConferenceRoom;
