// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint @typescript-eslint/no-floating-promises: 0 */
import { useMutation } from "@apollo/client";
import {
  Box,
  Slide,
  Fab,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import React, { useContext, useState } from "react";
import { userContext } from "../../../Context/context";
import { MessagesPageContext } from "../../../Pages/Messages/context";
import {
  SEND_MESSAGE_MUTATION,
  CREATE_MEDIA_MUTATION,
} from "../graphql/mutation";
import { IMessage } from "../typings";

import { ReactComponent as File } from "../../../assets/icons/chat/file.svg";
import { ReactComponent as Cross } from "../../../assets/icons/chat/cross.svg";

import { CustomScrollbarWrapper, useStylesInput } from "./Style";

import "./styles.css";

interface MessageInputProps {
  addMsg: (msg: IMessage) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({ addMsg }) => {
  const contextUserData = useContext(userContext);
  const { activeGroupId } = useContext(MessagesPageContext);

  const [message, setMessage] = useState("");

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);
  const [dataForFile, setDataForFile] = useState([]);
  const [errorWhenLoading, setErrorWhenLoading] = useState("");

  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const classes = useStylesInput();

  const [sendMessage] = useMutation<{ sendMessage: IMessage }>(
    SEND_MESSAGE_MUTATION,
    {
      errorPolicy: "all",
      variables: {
        data: {
          text: message,
          senderId: contextUserData.user.id,
          groupId: activeGroupId,
          files: dataForFile?.mediaUrl ? [dataForFile?.mediaUrl] : undefined,
        },
      },
    }
  );

  const handleMessage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setMessage(e.target.value);
  };

  const [createMedia] = useMutation<{ createMedia: { data: any } }>(
    CREATE_MEDIA_MUTATION
  );

  const handleSendMessage = async (): Promise<void> => {
    if (message.trim().length > 0 || dataForFile.length > 0) {
      try {
        const res = await sendMessage();
        if (res.errors) {
          console.error("GraphQL errors", res.errors);
          return;
        }
        if (dataForFile) {
          await fetch(dataForFile.signedURL, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            credentials: "include", // include, *same-origin, omit
            headers: {
              "Content-Type": file?.type,
            },
            body: file, // body data type must match "Content-Type" header
          });
        }
        addMsg(res.data!.sendMessage);
        setMessage("");
        setFile(null);
        setDataForFile([]);
      } catch (err) {
        console.error("error sending message", err);
      }
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      const isImageOrVideo =
        selectedFile.type.includes("image") ||
        selectedFile.type.includes("video");
      const isFileSizeValid = selectedFile.size <= 40 * 1024 * 1024; // 20 MB

      if (isImageOrVideo && isFileSizeValid) {
        setFile(selectedFile);
        const response = await createMedia({
          errorPolicy: "all",
          variables: {
            data: {
              fileType: "chatMedia",
            },
          },
        });
        if (response.errors) {
          alert("При загрузке файла произошла ошибка. Попробуйте еще раз");
          setFile(null);
          return;
        }

        setDataForFile([...dataForFile, response.data.createMedia]);
      } else {
        if (!isImageOrVideo) {
          alert("Вы можете загружать только видео и фото");
        } else if (!isFileSizeValid) {
          alert("Размер файла не должен превышать 20 Мб");
        }
        setFile(null);
        setDataForFile([]);
      }
      setFileInputKey(Date.now());
    } else {
      alert("Файл не выбран!");
      setFile(null);
      setDataForFile([]);
    }
  };

  const removeFile = () => {
    setFile(null);
    setDataForFile([]);
    setFileInputKey(Date.now());
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      if (!event.shiftKey) {
        handleSendMessage();
      }
    }
  };

  return (
    <Grid container className={`${classes.MessageInput} ${classes.inputField}`}>
      <Box>
        <CustomScrollbarWrapper>
          <TextField
            value={message}
            onChange={handleMessage}
            label="Сообщение"
            multiline
            maxRows={4}
            variant="outlined"
            fullWidth
            onKeyDown={onKeyDown}
            className={classes.input}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <input
                    key={fileInputKey}
                    type="file"
                    accept="image/*, video/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload">
                    <File
                      aria-label="upload file"
                      style={{ cursor: "pointer" }}
                    />
                  </label>
                </InputAdornment>
              ),
            }}
          />

          {file && (
            <Box
              display="flex"
              alignItems="center"
              className="file-box"
              // justifyContent="space-between"
              mt={1}
            >
              <div className="file-container">
                {file.type.startsWith("image") && (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Preview"
                    className="preview"
                  />
                )}
                {file.type.startsWith("video") && (
                  <video
                    src={URL.createObjectURL(file)}
                    className="preview"
                    controls
                  />
                )}
                <span className="file-text-style">{file.name}</span>
              </div>
              <span
                style={{
                  display: "flex",
                  alignItems: "start",
                  marginTop: -15,
                  marginLeft: 16,
                }}
              >
                <Cross style={{ cursor: "pointer" }} onClick={removeFile} />
              </span>
            </Box>
          )}
        </CustomScrollbarWrapper>
      </Box>
      <Box className={classes.sendButton} onClick={handleSendMessage}>
        <Slide direction="up" in={message.length > 0 || dataForFile.length > 0}>
          <Fab color="primary" aria-label="add">
            <SendIcon />
          </Fab>
        </Slide>
      </Box>
    </Grid>
  );
};

export default MessageInput;
