/* eslint-disable @typescript-eslint/ban-ts-comment*/
// @ts-nocheck

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { Grid, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import { Modal } from "react-responsive-modal";

import Alert from "@mui/material/Alert";
import Button from "../../UI/Buttons/OutlinedButton/Button";
import NoneClick from "../../UI/NoneClickableField/NoneClick";
import useStyles from "../../UI/Styles/TS/Components/createModalStyles/index";
import { ModalImage, ModalImageContainer } from "../../UI/Styles/TS/Style";

import imgModalDefault from "../../../assets/img/imgModal.svg";

import { categoriesOptions, defaultOption } from "../../../helpers/constants/categories";
import { CreateCompanyMutation, GetUrlToUploadMedia } from "../graphql/mutations";
import { CompanyCreateModalProps } from "./typings";

import "react-responsive-modal/styles.css";
import { innRegexp } from "../../../helpers/constants/constants";

const CreateCompanyModal: (props: CompanyCreateModalProps) => JSX.Element = (
  props: CompanyCreateModalProps
) => {
  const UrlToUploadPosterHandler = GetUrlToUploadMedia();

  const [nameFormValue, setNameFormValue] = useState("");
  const [descriptionFormValue, setDescriptionFormValue] = useState("");
  const [shortDescriptionFormValue, setShortDescriptionFormValue] = useState("");
  const [activityTypeFormValue, setActivityTypeFormValue] = useState(defaultOption);
  const [innFormValue, setInnFormValue] = useState("");
  const [mainRegionFormValue, setMainRegionFormValue] = useState("");
  const [mainContactFormValue, setMainContactFormValue] = useState("");

  const [isValidNameFormValue, setIsValidNameFormValue] = useState(true);
  const [isValidDescriptionFormValue, setIsValidDescriptionFormValue] =
    useState(true);
  const [
    isValidShortDescriptionFormValue,
    setIsValidShortDescriptionFormValue,
  ] = useState(true);
  const [isValidActivityTypeFormValue, setIsValidActivityTypeFormValue] =
    useState(true);

  useEffect(() => {
    setNameFormValue("");
    setDescriptionFormValue("");
    setShortDescriptionFormValue("");
    setActivityTypeFormValue(defaultOption);
    setInnFormValue("");
    setMainRegionFormValue("");
    setMainContactFormValue("");

    setIsValidNameFormValue(true);
    setIsValidDescriptionFormValue(true);
    setIsValidShortDescriptionFormValue(true);
    setIsValidActivityTypeFormValue(true);
  }, [props.open]);

  const [confirmCreate, setConfirmCreate] = useState(false);
  const [rejectCreate, setRejectCreate] = useState(false);

  const [posterValue, setPosterValue] = useState<{
    name: string;
    size: number;
    type: string;
  }>({
    name: "",
    type: "",
    size: 0,
  });

  const [imgModal, setImgModal] = useState(imgModalDefault);

  const [openNoneClick, setOpenNoneClick] = useState(false);

  const createCompanyHandler = CreateCompanyMutation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });
  const styles = useStyles();

  const onClickHandler: () => Promise<void> = async () => {
    if (
      isValidNameFormValue &
        isValidDescriptionFormValue &
        isValidShortDescriptionFormValue &
        isValidActivityTypeFormValue &&
      (innRegexp.test(innFormValue) || !innFormValue)
    ) {
      setOpenNoneClick(true);

      const newCompanyData = {
        name: nameFormValue,
        description: descriptionFormValue,
        shortDescription: shortDescriptionFormValue,
        activityKind: activityTypeFormValue.value,
        mainRegion: mainRegionFormValue,
        mainContact: mainContactFormValue,
        ...(innFormValue && { inn: innFormValue }),
      };

      const companyId = await createCompanyHandler(newCompanyData);

      if (posterValue.size !== 0) {
        const PosterData = {
          entityType: "companyAvatar",
          entityId: companyId?.data?.createCompany?.id,
          fileType: posterValue.type,
        };

        const uploadUrl = await UrlToUploadPosterHandler(PosterData);

        await fetch(uploadUrl.data!.createMedia?.signedURL, {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": posterValue.type,
          },
          //@ts-ignore
          body: posterValue,
        });
      }

      console.log("companyId");

      props.handleOpenClose();
      window.location.href = `/company/${companyId.data.createCompany.id}`;

      // window.location.reload();

      setConfirmCreate(true);
      setOpenNoneClick(false);
    } else {
      setRejectCreate(true);
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick: () => void = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const fileUploaded = event.target.files![0];
    if (fileUploaded.type === "image/jpeg" || fileUploaded.type === "image/png" || fileUploaded.type === "image/jpg") {
      setImgModal(URL.createObjectURL(fileUploaded));
      setPosterValue(fileUploaded);
    } else event.target.value.files = [];
  };

  const checkRequiredFields = () => {
    return (
      nameFormValue.length !== 0 &&
      descriptionFormValue.length !== 0 &&
      shortDescriptionFormValue.length !== 0 &&
      activityTypeFormValue.value.length !== 0
    );
  };

  const validateNameHandler = (): void =>
    nameFormValue.length !== 0
      ? setIsValidNameFormValue(true)
      : setIsValidNameFormValue(false);

  const validateDescriptionHandler = (): void =>
    descriptionFormValue.length !== 0
      ? setIsValidDescriptionFormValue(true)
      : setIsValidDescriptionFormValue(false);

  const validateShortDescriptionHandler = (): void =>
    shortDescriptionFormValue.length !== 0
      ? setIsValidShortDescriptionFormValue(true)
      : setIsValidShortDescriptionFormValue(false);

  const validateActivityTypeHandler = (): void =>
    activityTypeFormValue.value
      ? setIsValidActivityTypeFormValue(true)
      : setIsValidActivityTypeFormValue(false);

  const nameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setNameFormValue(e.target.value);
  const descriptionChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => setDescriptionFormValue(e.target.value);
  const shortDescriptionChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => setShortDescriptionFormValue(e.target.value);
  const activityTypeChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => setActivityTypeFormValue(e.target.value);
  const innChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setInnFormValue(e.target.value);
  const mainRegionChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => setMainRegionFormValue(e.target.value);
  const mainContactChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => setMainContactFormValue(e.target.value);

  const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }

    setConfirmCreate(false);
    setRejectCreate(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleOpenClose}
        center
        styles={{ modal: { width: isTabletOrMobile ? "" : 800 } }}
      >
        <Grid container direction="column" className={styles.modalContainer}>
          {openNoneClick ? <NoneClick /> : null}
          <Grid item className={styles.modalHeader}>
            Создание компании
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid item xs>
              <TextField
                fullWidth={true}
                defaultValue={nameFormValue}
                value={nameFormValue}
                label={isTabletOrMobile ? "Название *" : "Название компании *"}
                onChange={nameChangeHandler}
                inputProps={{
                  maxLength: 128,
                }}
                size="small"
                onBlurCapture={validateNameHandler}

                {...(!isValidNameFormValue) ? {
                  error: true,
                  helperText: "Заполните название компании"
                } : null}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid item xs>
              <TextField
                fullWidth={true}
                defaultValue={shortDescriptionFormValue}
                value={shortDescriptionFormValue}
                onChange={shortDescriptionChangeHandler}
                label={isTabletOrMobile ? "Краткое описание *" : "Краткое описание компании *"}
                inputProps={{
                  maxLength: 78,
                }}
                size="small"
                onBlurCapture={validateShortDescriptionHandler}

                {...(!isValidShortDescriptionFormValue) ? {
                  error: true,
                  helperText: "Заполните краткое описание компании"
                } : null}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid item xs>
              <TextField
                fullWidth={true}
                defaultValue={descriptionFormValue}
                value={descriptionFormValue}
                multiline
                onChange={descriptionChangeHandler}
                label={isTabletOrMobile ? "Описание *" : "Описание компании *"}
                inputProps={{
                  maxLength: 2048,
                }}
                size="small"
                onBlurCapture={validateDescriptionHandler}

                {...(!isValidDescriptionFormValue) ? {
                  error: true,
                  helperText: "Заполните описание компании"
                } : null}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid item xs>
              <TextField
                type="number"
                fullWidth={true}
                defaultValue={innFormValue}
                value={innFormValue}
                onChange={innChangeHandler}
                label={isTabletOrMobile ? "ИНН" : "ИНН компании"}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "/^[0-9]{10}(?:[0-9]{2})?$/",
                  maxLength: 12,
                }}
                size="small"
                {...(!innRegexp.test(innFormValue) && innFormValue) ? {
                  error: true,
                  helperText: "ИНН должен содержать 10 или 12 цифр",
                } : null}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid item xs>
              <TextField
                fullWidth={true}
                defaultValue={mainRegionFormValue}
                value={mainRegionFormValue}
                onChange={mainRegionChangeHandler}
                label={isTabletOrMobile ? "Регион" : "Регион компании"}
                inputProps={{
                  maxLength: 128,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid item xs>
              <TextField
                fullWidth={true}
                defaultValue={mainContactFormValue}
                value={mainContactFormValue}
                onChange={mainContactChangeHandler}
                label={isTabletOrMobile ? "Контакты" : "Информация для связи"}
                inputProps={{
                  maxLength: 512,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems={"center"}
            className={styles.inputContainerGap}
          >
            {<Grid>Выберите категорию*</Grid>}
            <Grid container md={5} xs={12}>
              <Select
                value={activityTypeFormValue}
                onChange={activityTypeChangeHandler}
                fullWidth
                size="small"
                onBlurCapture={validateActivityTypeHandler}

                {...(!isValidActivityTypeFormValue) ? {
                  error: true,
                  helperText: "Выберите категорию вашей компании"
                } : null}
              >
                <MenuItem disabled value={defaultOption}>{defaultOption.label}</MenuItem>
                {categoriesOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid container xs direction="column" style={{ gap: 15 }}>
              {isTabletOrMobile ? null : (
                <Grid>Выберите изображение для обложки вашей компании</Grid>
              )}
              <Grid container direction="row" style={{ gap: 20 }}>
                <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: "none" }} accept='.png, .jpg, .jpeg' />
                <ModalImageContainer
                  container
                  justifyContent="center"
                  alignItems="center"
                  onClick={handleClick}
                  md={5}
                  xs={5}
                  isDefault={imgModal === imgModalDefault}
                >
                  <ModalImage
                    src={imgModal}
                    isDefault={imgModal === imgModalDefault}
                  />
                </ModalImageContainer>
                <Grid
                  container
                  xs
                  alignItems="center"
                  className={styles.imgConditionText}
                >
                  JPEG или PNG
                </Grid>
              </Grid>
            </Grid>
          </Grid>


          

          {isTabletOrMobile ? (
            <Grid
              container
              direction="column"
              className={styles.modalMobileButtonContainer}
            >
              <Button
                isDisable={!checkRequiredFields()}
                onClick={onClickHandler}
                text="Создать"
                className={styles.modalButton}
              />
              <Button
                onClick={props.handleOpenClose}
                isCancel={true}
                text="Отменить"
                className={styles.modalButton}
              />
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                xs={9}
                className={styles.modalButtonContainer}
              >
                <Grid container xs>
                  <Button
                    onClick={props.handleOpenClose}
                    isCancel={true}
                    text="Отменить"
                    className={styles.modalButton}
                  />
                </Grid>
                <Grid container xs>
                  <Button
                    isDisable={!checkRequiredFields()}
                    onClick={onClickHandler}
                    text="Создать"
                    className={styles.modalButton}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Modal>

      <Snackbar
        open={rejectCreate}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity='error'
          sx={{ width: "100%" }}
        >
          Заполните все необходимые данные
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmCreate}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity='success'
          sx={{ width: "100%" }}
        >
          Компания создана
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateCompanyModal;
