import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import DownloadIcon from "@mui/icons-material/Download";
import { userContext } from "../../../Context/context";

import { useStylesMessages } from "./Style";
import { IMessage } from "../typings";
import { Avatar, Typography } from "@mui/material";

interface MessageProps {
  message: IMessage;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  const classes = useStylesMessages();
  const Context = useContext(userContext);

  const time = new Date(message.createdAt.toString());
  const messageTime = `${`0${time.getHours()}`.slice(
    -2
  )}:${`0${time.getMinutes()}`.slice(-2)} ${`0${time.getDate()}`.slice(
    -2
  )}.${`0${time.getMonth() + 1}`.slice(-2)}.${time.getFullYear()}`;

  const handleFileDownload = async (fileUrl: string) => {
    try {
      // Получаем файл как Blob
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();

      // Создаём URL для Blob
      const blobUrl = window.URL.createObjectURL(blob);

      // Создаём временную ссылку и симулируем клик
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = fileUrl.split("/").pop(); // Имя файла
      document.body.appendChild(anchor);
      anchor.click();

      // Удаляем ссылку и освобождаем URL Blob
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error during file download", error);
    }
  };

  return (
    <ListItem>
      <Grid
        container
        justifyContent={
          message.sender.id === Context.user.id ? "flex-end" : "flex-start"
        }
        className={classes.messageBox}
        style={{ wordBreak: "break-word" }}
      >
        <Grid
          style={{ display: "flex" }}
          className={
            message.sender.id === Context.user.id
              ? classes.messageContainer
              : classes.messageContainerOther
          }
        >
          <Avatar
            src={message.sender.avatar?.link}
            style={{ marginRight: 12 }}
          />
          <Grid>
            <Typography
              fontWeight={700}
              style={{ marginBottom: 5 }}
            >{`${message.sender.firstname} ${message.sender.lastname}`}</Typography>
            {message.files && message.files.length > 0 && (
              <>
                {message.files.map((file, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", gap: 8, cursor: "pointer" }}
                    onClick={() => handleFileDownload(file)}
                  >
                    <span className={classes.messageText}>File</span>
                    <DownloadIcon />
                  </div>
                ))}
              </>
            )}
            <Grid className={classes.messageText}>{message.text}</Grid>
            <Grid className={classes.messageTime}>{messageTime}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default Message;
