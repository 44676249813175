// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";

import Loading from "../../UI/Loading/Loading";
import { MessagesPageContext } from "../../../Pages/Messages/context";
import GroupItem from "../GroupItem/GroupItem";
import GroupSearch from "../GroupSearch/GroupSearch";
import CreateGroup from "./CreateGroupModal/CreateGroup";
import { useWindowSize } from "../../../rules/index";
import { GET_MY_GROUPS } from "../graphql/query";
import { IMyGroup } from "../typings";

import { GroupsSidebar, MenuToggleBtn } from "./style";
import { DivMessagePageScrollbar } from "../style";

const GroupSidebar: React.FC = () => {
  const { activeGroupId } = useContext(MessagesPageContext);
  const [width] = useWindowSize();

  const { data, loading } = useQuery<{ getMyGroups: IMyGroup[] }>(
    GET_MY_GROUPS,
    {
      pollInterval: 5000
    }
  );
  const [filteredGroups, setFilteredGroups] = useState<IMyGroup[] | undefined>(
    data?.getMyGroups
  );

  useEffect(() => {
    if (!data) return;
    setFilteredGroups(data.getMyGroups);
  }, [data]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuToggle = (): void => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    handleMenuToggle();
  }, [activeGroupId]);

  return (
    <GroupsSidebar
      item
      $isMenuOpen={isMenuOpen}
      style={{ maxHeight: "calc(100vh - 120px)" }}
    >
      <MenuToggleBtn
        onClick={handleMenuToggle}
        $isMenuOpen={isMenuOpen}
        style={{ marginRight: 10 }}
      >
        {isMenuOpen ? <CloseIcon /> : <ChatIcon />}
      </MenuToggleBtn>
      <Grid
        container
        style={{ padding: width > 900 ? "0 30px" : "0 40px", gap: 20 }}
      >
        <GroupSearch
          groups={data?.getMyGroups}
          setFilteredGroups={setFilteredGroups}
        />
        <CreateGroup />
      </Grid>
      <DivMessagePageScrollbar>
        {loading ? (
          // Докинуть сюда скелетонов
          <Loading />
        ) : (
          filteredGroups?.map(group => {
            return <GroupItem key={group.id} group={group} />;
          })
        )}
      </DivMessagePageScrollbar>
    </GroupsSidebar>
  );
};
export default GroupSidebar;
