import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";

import {
  Grid,
  Avatar,
  Button,
  CssBaseline,
  Paper,
  TextField,
  Typography,
  Snackbar,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { SIGN_UP_MUTATION } from "./Graphql/Mutations";
import { UserCreateInput } from "./Graphql/typings";

import NavBar from "../../Components/UI/NavBar/NavBar";
import { Copyright } from "../../Components/Copyright/Copyright";
import { validateEmail } from "../../utils/validateEmail";
import { validateLogin } from "../../utils/validateLogin";
import { validatePassword } from "../../utils/validatePassword";

import useStyles from "./Style";

const SignUp: () => JSX.Element = () => {
  const classes = useStyles();

  const [fNameFormValue, setFNameFormValue] = useState("");
  const [lNameFormValue, setLNameFormValue] = useState("");
  const [loginFormValue, setLoginFormValue] = useState("");
  const [emailFormValue, setEmailFormValue] = useState("");
  const [passwordFormValue, setPasswordFormValue] = useState("");

  const [isValidFName, setIsValidFName] = useState(true);
  const [isValidLName, setIsValidLName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  
  const [confirmRegister, setConfirmRegister] = useState(false);
  const [rejectRegister, setRejectRegister] = useState(false);
  
  const history = useHistory();
  
  const handleClickShowPassword = (): void => setShowPassword(!showPassword);

  const validateFNameHandler = (): void => fNameFormValue ? setIsValidFName(true) : setIsValidFName(false);

  const validateLNameHandler = (): void => lNameFormValue ? setIsValidLName(true) : setIsValidLName(false);

  const validateEmailHandler = (): void => emailFormValue && validateEmail(emailFormValue) ? setIsValidEmail(true) : setIsValidEmail(false);

  const validateLoginHandler = (): void => loginFormValue && validateLogin(loginFormValue) ? setIsValidLogin(true) : setIsValidLogin(false);

  const validatePasswordHandler = (): void => passwordFormValue && validatePassword(passwordFormValue) ? setIsValidPassword(true) : setIsValidPassword(false);

  const fNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => setFNameFormValue(e.target.value);

  const lNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => setLNameFormValue(e.target.value);

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => setEmailFormValue(e.target.value);

  const loginChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => setLoginFormValue(e.target.value);

  const passwordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => setPasswordFormValue(e.target.value);

  const [signUpHandler, { data }] = useMutation<{ signUp: { token: string }, errors?: any[] }>(
    SIGN_UP_MUTATION
  );

  const snackbarOpenClosedHandler = (reason: string): void => {
    if (reason === "success") {
      setConfirmRegister(true);
    }

    if (reason === "reject") {
      setRejectRegister(true);
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }

    setConfirmRegister(false);
    setRejectRegister(false);
  };

  useEffect(() => {
    if (data && data.signUp) {
      localStorage.setItem("token", data.signUp.token);
      snackbarOpenClosedHandler("success");

      history.push("/signin");
    } else if (data && data.signUp === null) {
        snackbarOpenClosedHandler("reject");
    }
  }, [data, history]);


  const signUpSubmit = async (e: React.FormEvent): Promise<void> => {

      e.preventDefault();
      
      if (
        isValidFName &&
        isValidLName &&
        isValidEmail &&
        isValidLogin &&
        isValidPassword
      ) {

        const userInput: UserCreateInput = {
          firstname: fNameFormValue,
          lastname: lNameFormValue,
          email: emailFormValue,
          login: loginFormValue,
          password: passwordFormValue,
        };
        
        await signUpHandler({
          variables: {
            data: userInput,
          },
        });
      } else {
        snackbarOpenClosedHandler("reject");
      }
    };

  return (
    <Grid container component='main' className={classes.root}>
      <Grid container style={{ height: "10vh" }}>
        <NavBar text='qwe' topIdent={1} bottomIdent={1} />
      </Grid>
      <Grid container component='main' style={{ height: "90vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          style={{
            backgroundImage: "url(headerImage.webp)",
          }}
          className={classes.image}
        />
        <Grid
          item
          container
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component='h1' variant='h5'>
              Регистрация
            </Typography>

            <form
              className={classes.form}
              onSubmit={signUpSubmit}
              noValidate
            >
              <TextField
                id='fName'
                label='Имя'
                name='fName'
                type='text'

                className={classes.textField}
                variant='outlined'
                margin='normal'
                fullWidth

                required
                value={fNameFormValue}
                onChange={fNameChangeHandler}
                onBlurCapture={validateFNameHandler}

                {...(!isValidFName) ? {
                  error: true,
                  helperText: "Неверный формат имени"
                } : null}

              />

              <TextField
                id='lName'
                label='Фамилия'
                name='lName'
                type='text'

                className={classes.textField}
                variant='outlined'
                margin='normal'
                fullWidth
                
                required
                value={lNameFormValue}
                onChange={lNameChangeHandler}
                onBlurCapture={validateLNameHandler}

                {...(!isValidLName) ? {
                  error: true,
                  helperText: "Неверный формат фамилии"
                } : null}
              />

              <TextField
                id='email'
                label='Адрес эл. почты'
                name='email'
                type='email'
                
                className={classes.textField}
                variant='outlined'
                margin='normal'
                fullWidth
                
                required
                value={emailFormValue}
                onChange={emailChangeHandler}
                onBlurCapture={validateEmailHandler}

                {...(!isValidEmail) ? {
                  error: true,
                  helperText: "Неверный формат почты"
                } : null}
              />

              <TextField
                id='login'
                label='Логин'
                name='login'
                type='login'

                className={classes.textField}
                variant='outlined'
                margin='normal'
                fullWidth
                
                required
                value={loginFormValue}
                onChange={loginChangeHandler}
                onBlurCapture={validateLoginHandler}

                {...(!isValidLogin) ? {
                  error: true,
                  helperText: "Неверный формат логина"
                } : null}
              />

              <TextField
                id='password'
                label='Пароль'
                name='password'
                type={showPassword ? "text" : "password"}

                className={classes.textField}
                variant='outlined'
                margin='normal'
                fullWidth
                
                required
                value={passwordFormValue}
                onChange={passwordChangeHandler}
                onBlurCapture={validatePasswordHandler}

                {...(!isValidPassword) ? {
                  error: true,
                  helperText: "Неверный формат пароля"
                } : null}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <Button
                type='submit'
                fullWidth
                variant='contained'
                className={classes.submitButton}
                onClick={signUpSubmit}
              >
                Зарегистрироваться
              </Button>

              <Snackbar
                open={rejectRegister}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity='error'
                  sx={{ width: "100%" }}
                >
                  Регистрационные данные некорректны
                </Alert>
              </Snackbar>

              <Snackbar
                open={confirmRegister}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity='success'
                  sx={{ width: "100%" }}
                >
                  Регистрация прошла успешно
                </Alert>
              </Snackbar>
              
            </form>
          </div>
          
          <Grid
            container
            justifyContent='center'
            alignItems='flex-end'
            style={{ marginBottom: 10 }}
          >
            <Copyright />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUp;
