// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useMutation } from "@apollo/client";
import Grid from "@mui/material/Grid";
import Chip from "@material-ui/core/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import NoneClick from "../../UI/NoneClickableField/NoneClick";
import SnackbarOnChange from "../../UI/Snackbar/Snackbar";
import { CREATE_CONTACT_MUTATION } from "../graphql/mutation";
import { ContactsCreateModalProps } from "./graphql/typing";
import ButtonsForModal from "../../All/ButtonsForModal";

import useStyles from "../../UI/Styles/TS/Components/createModalStyles/index";
import { validatePhone } from "../../../utils/validatePhone";
import { validateEmail } from "../../../utils/validateEmail";

const ContactCreationModal: (props: ContactsCreateModalProps) => JSX.Element = (
  props: ContactsCreateModalProps
) => {
  const [phoneValue, setPhoneValue] = useState([]);
  const [addressValue, setAddressValue] = useState([]);
  const [emailValue, setEmailValue] = useState([]);

  useEffect(() => {
    setPhoneValue([]);
    setAddressValue([]);
    setEmailValue([]);
  }, [props.open]);

  const [openNoneClick, setOpenNoneClick] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const [createContactHandler] = useMutation(CREATE_CONTACT_MUTATION);

  const handleDelete = e => {
    console.log("handleDelete", e);
  };

  const onClickHandler: () => Promise<void> = async () => {
    if (emailValue && addressValue && phoneValue) {
      setOpenNoneClick(true);
      const newContactData = {
        emails: emailValue,
        adresses: addressValue,
        phones: phoneValue,
        companyId: props.ownerId,
      };
      await createContactHandler({
        variables: {
          data: newContactData,
        },
      })
        .then(() => {
          props.handleOpenClose();
        })
        .catch(err => {
          console.error(err);
        });
      await props.refetch();
      setOpenNoneClick(false);
    } else {
      setOpenSnack(true);
      setTimeout(() => setOpenSnack(false), 4000);
    }
  };

  const checkFields = () => {
    return (
      phoneValue.length == 0 ||
      emailValue.length == 0 ||
      addressValue.length == 0
    );
  };

  const styles = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleOpenClose}
        center
        styles={{ modal: { width: isTabletOrMobile ? "" : 800 } }}
      >
        <Grid container direction="column" className={styles.modalContainer}>
          {openNoneClick ? <NoneClick /> : null}
          <Grid item className={styles.modalHeader}>
            Создание контактов
          </Grid>

          <Grid
            container
            md={3}
            xs={12}
            className={styles.modalHeaderText}
            style={{ paddingTop: isTabletOrMobile ? 0 : 10 }}
          >
            Для сохранения данных после ввода необходимо нажать Enter
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              className={styles.modalHeaderText}
              style={{ paddingTop: isTabletOrMobile ? 0 : 10 }}
            >
              Номера телефонов
            </Grid>
            <Grid item xs>
              <Autocomplete
                disableClearable
                multiple
                id="tags-filled"
                options={[]}
                defaultValue={phoneValue}
                value={phoneValue}
                freeSolo
                isOptionEqualToValue={(option, value) => {
                  return phoneValue.includes(option);
                }}
                placeholder="Нажмите Enter для каждого из номеров телефонов"
                onChange={e => {
                  if (validatePhone(e.target.value)) {
                    setPhoneValue([...phoneValue, e.target.value]);
                  }
                }}
                renderTags={(value: string[], getTagProps) =>
                  phoneValue.map((option: string, index: number) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      name={option}
                      variant="outlined"
                      label={option}
                      onDelete={() => {
                        setPhoneValue(
                          [...phoneValue].filter(it => it != option)
                        );
                      }}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder={phoneValue.length == 0 ? "+79185399910" : ""}
                    label="Номера телефонов"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              className={styles.modalHeaderText}
              style={{ paddingTop: isTabletOrMobile ? 0 : 10 }}
            >
              Электронные адреса
            </Grid>
            <Grid item xs>
              <Autocomplete
                multiple
                disableClearable
                id="tags-filled"
                options={[]}
                defaultValue={emailValue}
                freeSolo
                onChange={e => {
                  if (validateEmail(e.target.value)) {
                    setEmailValue([...emailValue, e.target.value]);
                  }
                }}
                isOptionEqualToValue={(option, value) => {
                  return emailValue.includes(option);
                }}
                renderTags={(value: string[], getTagProps) =>
                  emailValue.map((option: string, index: number) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      variant="outlined"
                      label={option}
                      onDelete={() => {
                        setEmailValue(
                          [...emailValue].filter(it => it != option)
                        );
                      }}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Email"
                    placeholder={
                      emailValue.length == 0 ? "info@magicamedia.com" : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              className={styles.modalHeaderText}
              style={{ paddingTop: isTabletOrMobile ? 0 : 10 }}
            >
              Адреса
            </Grid>
            <Grid item xs>
              <Autocomplete
                multiple
                disableClearable
                id="tags-filled"
                options={[]}
                onDelete={handleDelete}
                defaultValue={addressValue}
                freeSolo
                onChange={e => {
                  setAddressValue([...addressValue, e.target.value]);
                }}
                isOptionEqualToValue={(option, value) => {
                  return addressValue.includes(option);
                }}
                renderTags={(value: string[], getTagProps) =>
                  addressValue.map((option: string, index: number) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      variant="outlined"
                      label={option}
                      onDelete={() => {
                        setAddressValue(
                          [...addressValue].filter(it => it != option)
                        );
                      }}
                    />
                  ))
                }
                placeholder="Нажмите Enter для каждого из адресов"
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Адреса"
                    placeholder={
                      addressValue.length == 0
                        ? "пр. Михаила Нагибина, 3а, Ростов-на-Дону"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <ButtonsForModal
            isDisableFunc={checkFields}
            onClickHandlerOK={onClickHandler}
            onClickHandlerClose={props.handleOpenClose}
          />

          <SnackbarOnChange
            openSnack={openSnack}
            setOpenSnack={setOpenSnack}
            isError
            textInSnack="Проверьте обязательные поля (*) на заполненность"
          />
        </Grid>
      </Modal>
    </div>
  );
};

export default ContactCreationModal;
