import React from "react";
import { DivMessagePageScrollbar } from "../style";
import { MessagesBlockProps } from "../typings";
import Message from "./Message";

const MessagesBlock: React.FC<MessagesBlockProps> = ({ messages }) => {
  const sortedMessages = [...messages].sort((a, b) => {
    // Convert createdAt to Date objects if they aren't already
    const dateA = a.createdAt instanceof Date ? a.createdAt : new Date(a.createdAt);
    const dateB = b.createdAt instanceof Date ? b.createdAt : new Date(b.createdAt);
    
    return dateA.getTime() - dateB.getTime();
  });
  return (
    <DivMessagePageScrollbar
      style={{
        overflowY: "scroll",
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <div>
        {sortedMessages.map(message => (
            <Message key={message.id} message={message} />
          ))}
      </div>
    </DivMessagePageScrollbar>
  );
};

export default MessagesBlock;
