import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import {
  Grid,
  Avatar,
  Button,
  CssBaseline,
  Link,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import { Copyright } from "../../Components/Copyright/Copyright";
import NavBar from "../../Components/UI/NavBar/NavBar";
import { RESET_PASSWORD_MUTATION } from "./Graphql/Mutations";
import useStyles from "./Style";

import { LogInInput } from "./Graphql/typings";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { validatePassword } from "../../utils/validatePassword";
import { validateEmail } from "../../utils/validateEmail";
import { validateLogin } from "../../utils/validateLogin";

const ResetPassword: () => JSX.Element = () => {
  const classes = useStyles();

  const [emailFormValue, setEmailFormValue] = useState("");
  const [passwordFormValue, setPasswordFormValue] = useState("");

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  const [confirmReset, setConfirmReset] = useState(false);
  const [rejectLogin, setRejectLogin] = useState(false);

  const validateEmailHandler = (): void =>
    emailFormValue &&
    (validateEmail(emailFormValue) || validateLogin(emailFormValue))
      ? setIsValidEmail(true)
      : setIsValidEmail(false);

  const validatePasswordHandler = (): void =>
    passwordFormValue && validatePassword(passwordFormValue)
      ? setIsValidPassword(true)
      : setIsValidPassword(false);

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setEmailFormValue(e.target.value);

  const passwordChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => setPasswordFormValue(e.target.value);

  const handleClickShowPassword = (): void => setShowPassword(!showPassword);

  const [restorePassword, { data }] = useMutation<{
    signIn: null; restorePassword: { email: string } 
}>(
    RESET_PASSWORD_MUTATION
  );
 

  const snackbarOpenClosedHandler = (reason: string): void => {
    if (reason === "success") {
      setConfirmReset(true);
    }

    if (reason === "reject") {
      setRejectLogin(true);
    }
  };

  const history = useHistory();

  const logInSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (isValidEmail && isValidPassword) {
      const userInput: any= {
        email: emailFormValue,
      };

      const res = await restorePassword({
        variables: {
          // input: {
            email: userInput
          // },
        },
      });
      if (res.data?.restorePassword === null) {
        snackbarOpenClosedHandler("reject");
        setTimeout(() => setRejectLogin(false), 4000);
      } else {
        snackbarOpenClosedHandler("success");
        setTimeout(()=> history.push("/login"), 3000);
      }
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }

    setConfirmReset(false);
    setRejectLogin(false);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid container style={{ height: "10vh" }}>
        <NavBar text="qwe" topIdent={1} bottomIdent={1} />
      </Grid>
      <Grid
        container
        component="main"
        style={{ height: "90vh"}}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          style={{
            backgroundImage: "url(../headerImage.webp)",
          }}
          className={classes.image}
        />
        <Grid
          item
          container
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          
        >
          <div className={classes.paper} style={{
            width: "100%"
          }}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Смена пароля
            </Typography>

            <form className={classes.form} onSubmit={logInSubmit} noValidate>
              {/* <form
              className={classes.form}
              onSubmit={(e: React.FormEvent): void => {
                e.preventDefault();
                const userInput: LogInInput = {
                  login: emailFormValue,
                  password: passwordFormValue,
                };
                logInHandler({
                  variables: {
                    input: userInput,
                  },
                })
                  .then()
                  .catch(err => console.error(err));
              }}
              noValidate
            > */}

              <TextField
                id="email"
                label="Адрес эл. почты"
                name="email"
                type="email"
                className={classes.textField}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                value={emailFormValue}
                onChange={emailChangeHandler}
                onBlurCapture={validateEmailHandler}
                {...(!isValidEmail
                  ? {
                      error: true,
                      helperText: "Неверный формат почты",
                    }
                  : null)}
              />

              {/* <TextField
                id="password"
                label="Пароль"
                name="password"
                type={showPassword ? "text" : "password"}
                className={classes.textField}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                value={passwordFormValue}
                onChange={passwordChangeHandler}
                onBlurCapture={validatePasswordHandler}
                {...(!isValidPassword
                  ? {
                      error: true,
                      helperText: "Неверный формат пароля",
                    }
                  : null)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}

              {/* <FormControlLabel
                control={<Checkbox value='remember' />}
                label='Запомнить меня'
                className={classes.controlLabel}
              /> */}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submitButton}
                onClick={logInSubmit}
              >
                Смена пароля
              </Button>

              {/* <Button
                type='submit'
                fullWidth
                variant='contained'
                className={classes.submitButton}
                onClick={logInHandler}
              >
                Войти
              </Button> */}

              <Snackbar
                open={rejectLogin}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Регистрационные данные некорректны
                </Alert>
              </Snackbar>

              <Snackbar
                open={confirmReset}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Запрос на сброс пароля отправлен, проверьте указанную почту
                </Alert>
              </Snackbar>
            </form>
          </div>

          <Grid
            container
            justifyContent="center"
            alignItems="flex-end"
            style={{ marginBottom: 10 }}
          >
            <Copyright />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={rejectLogin}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Регистрационные данные некорректны
        </Alert>
      </Snackbar>

    </Grid>
  );
};

export default ResetPassword;
