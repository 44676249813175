/* eslint-disable @typescript-eslint/ban-ts-comment*/
// @ts-nocheck
import React, { useState, useEffect } from "react";

import { useMediaQuery } from "react-responsive";
import { useQuery } from "@apollo/client";
import { Grid, TextField, MenuItem, Select, Snackbar } from "@mui/material";

import { Modal } from "react-responsive-modal";

import Alert from "@mui/material/Alert";
import NoneClick from "../../../Components/UI/NoneClickableField/NoneClick";
import Button from "../../../Components/UI/Buttons/OutlinedButton/Button";
import SnackbarOnChange from "../../../Components/UI/Snackbar/Snackbar";
import useStyles from "../../../Components/UI/Styles/TS/Components/createModalStyles/index";
import {
  ModalImage,
  ModalImageContainer,
} from "../../../Components/UI/Styles/TS/Style";

import imgModalDefault from "../../../assets/img/imgModal.svg";
import { categoriesArray, categoriesOptions, defaultOption } from "../../../helpers/constants/categories";
import { useTranslate } from "../../../helpers/hooks/useTranslateCategories";

import { GET_COMPANY_QUERY } from "./graphql/query";
import { CompanyTypes, CompanyEditModalProps } from "./graphql/typings";
import {
  UpdateMyCompanyMutation,
  GetUrlToUploadCompanyAvatar,
} from "./graphql/mutation";

import "react-responsive-modal/styles.css";
import { innRegexp } from "../../../helpers/constants/constants";


const CompanyEditModal: (props: CompanyEditModalProps) => JSX.Element = (
  props: CompanyEditModalProps
) => {
  const styles = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });

  const { data, loading, refetch } = useQuery<{ company: CompanyTypes }>(
    GET_COMPANY_QUERY,
    {
      variables: { companyId: Number(props.companyId) },
    }
  );

  const updateCompanyHandler = UpdateMyCompanyMutation();
  const UrlToUploadAvatarHandler = GetUrlToUploadCompanyAvatar();

  const [nameFormValue, setNameFormValue] = useState("");
  const [shortDescriptionFormValue, setShortDescriptionFormValue] =
    useState("");
  const [descriptionFormValue, setDescriptionFormValue] = useState("");
  const [innFormValue, setInnFormValue] = useState("");
  const [mainRegionFormValue, setMainRegionFormValue] = useState("");
  const [mainContactFormValue, setMainContactFormValue] = useState("");
  const [catFormValue, setCatFormValue] = useState(defaultOption);

  const [avatarValue, setAvatarValue] = useState<{
    name: string;
    size: number;
    type: string;
  }>({
    name: "",
    type: "",
    size: 0,
  });
  const [imgModal, setImgModal] = useState(imgModalDefault);

  const [openSnack, setOpenSnack] = useState(false);
  const [openNoneClick, setOpenNoneClick] = useState(false);

  const [confirmCreate, setConfirmCreate] = useState(false);
  const [rejectCreate, setRejectCreate] = useState(false);

  const handleChangeCategory = (event: SelectChangeEvent): void =>
    setCatFormValue(event.target.value);

  useEffect(() => {
    if (!loading && data) {
      setNameFormValue(data.company.name);
      setShortDescriptionFormValue(data.company.shortDescription);
      setDescriptionFormValue(data.company.description);
      setInnFormValue(data.company.inn);
      setMainRegionFormValue(data.company.mainRegion);
      setMainContactFormValue(data.company.mainContact);
      setCatFormValue(categoriesOptions.find(option => option.value === data.company.activityKind));
      setImgModal(data.company.avatar.link);
    }
  }, [loading, data]);

  const onClickHandler: () => Promise<void> = async () => {
    if (
      nameFormValue &&
      shortDescriptionFormValue &&
      descriptionFormValue &&
      catFormValue &&
      (innRegexp.test(innFormValue) || !innFormValue)
    ) {
      setOpenNoneClick(true);

      const newCompanyData = {
        name: nameFormValue,
        shortDescription: shortDescriptionFormValue,
        description: descriptionFormValue,
        activityKind: catFormValue.value,
        companyId: props.companyId,
        mainRegion: mainRegionFormValue,
        mainContact: mainContactFormValue,
        ...(innFormValue && { inn: innFormValue }),
      };

      await updateCompanyHandler(newCompanyData);

      if (avatarValue.size !== 0) {
        const AvatarData = {
          entityType: "companyAvatar",
          entityId: props.companyId,
          fileType: avatarValue.type,
        };

        const uploadUrl = await UrlToUploadAvatarHandler(AvatarData);

        await fetch(uploadUrl.data!.createMedia?.signedURL, {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": avatarValue.type,
          },
          //@ts-ignore
          body: avatarValue,
        });
      }

      props.handleOpenClose();

      await refetch();

      setConfirmCreate(true);
      setOpenNoneClick(false);
    } else {
      setRejectCreate(true);
      setTimeout(() => setOpenSnack(false), 4000);
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick: () => void = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const fileUploaded = event.target.files![0];
    setImgModal(URL.createObjectURL(fileUploaded));
    setAvatarValue(fileUploaded);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }

    setConfirmCreate(false);
    setRejectCreate(false);
  };

  const setNameValueHandler = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setNameFormValue(e.target.value);
  const setShortDescriptionValueHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => setShortDescriptionFormValue(e.target.value);
  const setDescriptionValueHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => setDescriptionFormValue(e.target.value);
  const setInnValueHandler = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setInnFormValue(e.target.value);
  const setMainRegionValueHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => setMainRegionFormValue(e.target.value);
  const setMainContactValueHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => setMainContactFormValue(e.target.value);

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleOpenClose}
        center
        styles={{ modal: { width: isTabletOrMobile ? "" : 800 } }}
      >
        <Grid container direction="column" className={styles.modalContainer}>
          {openNoneClick ? <NoneClick /> : null}

          <Grid item className={styles.modalHeader}>
            Редактирование компании
          </Grid>
          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Название
            </Grid>
            <Grid container xs>
              <TextField
                fullWidth={true}
                defaultValue={nameFormValue}
                value={nameFormValue}
                placeholder={isTabletOrMobile ? "*" : "Название компании *"}
                onChange={setNameValueHandler}
                inputProps={{
                  maxLength: 128,
                }}
                size="small"
                {...(!nameFormValue) ? {
                  error: true,
                  helperText: "Заполните название компании"
                } : null}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Краткое описание
            </Grid>
            <Grid container xs>
              <TextField
                fullWidth={true}
                defaultValue={shortDescriptionFormValue}
                value={shortDescriptionFormValue}
                onChange={setShortDescriptionValueHandler}
                placeholder={
                  isTabletOrMobile ? "*" : "Краткое описание компании *"
                }
                inputProps={{
                  maxLength: 78,
                }}

                {...(!shortDescriptionFormValue) ? {
                  error: true,
                  helperText: "Заполните краткое описание компании"
                } : null}

                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Описание
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth={true}
                defaultValue={descriptionFormValue}
                value={descriptionFormValue}
                onChange={setDescriptionValueHandler}
                placeholder={isTabletOrMobile ? "*" : "Описание компании *"}
                inputProps={{
                  maxLength: 2048,
                }}
                size="small"
                multiline
                {...(!descriptionFormValue) ? {
                  error: true,
                  helperText: "Заполните описание компании"
                } : null}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              ИНН компании
            </Grid>
            <Grid item xs>
              <TextField
                type="number"
                fullWidth={true}
                defaultValue={innFormValue}
                value={innFormValue}
                onChange={setInnValueHandler}
                placeholder={isTabletOrMobile ? "" : "ИНН компании"}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]{12}$",
                  maxLength: 12,
                }}
                {...(!innRegexp.test(innFormValue) && innFormValue) ? {
                  error: true,
                  helperText: "ИНН должен содержать 12 цифр"
                } : null}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Регион
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth={true}
                defaultValue={mainRegionFormValue}
                value={mainRegionFormValue}
                onChange={setMainRegionValueHandler}
                placeholder={isTabletOrMobile ? "*" : "Регион компании"}
                inputProps={{
                  maxLength: 128,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Информация для связи
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth={true}
                defaultValue={mainContactFormValue}
                value={mainContactFormValue}
                onChange={setMainContactValueHandler}
                placeholder={
                  isTabletOrMobile
                    ? "*"
                    : "Информация для связи (соц.сеть, tg, телефон)"
                }
                inputProps={{
                  maxLength: 512,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Категория
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                value={catFormValue}
                onChange={handleChangeCategory}
                fullWidth
                size="small"
              >
                {categoriesOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid container xs direction="column" style={{ gap: 15 }}>
              {isTabletOrMobile ? null : (
                <Grid>Выберете изображение для обложки компании</Grid>
              )}
              <Grid container direction="row" style={{ gap: 20 }}>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <ModalImageContainer
                  container
                  justifyContent="center"
                  alignItems="center"
                  onClick={handleClick}
                  md={5}
                  xs={5}
                  isDefault={imgModal === imgModalDefault}
                >
                  <ModalImage
                    src={imgModal}
                    isDefault={imgModal === imgModalDefault}
                  />
                </ModalImageContainer>
                <Grid
                  container
                  xs
                  alignItems="center"
                  className={styles.imgConditionText}
                >
                  JPEG или PNG
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {isTabletOrMobile ? (
            <Grid
              container
              direction="column"
              style={{ marginTop: 30, gap: 20 }}
            >
              <Button
                onClick={onClickHandler}
                text="Сохранить"
                className={styles.modalButton}
              />
              <Button
                onClick={props.handleOpenClose}
                isCancel={true}
                text="Отменить"
                className={styles.modalButton}
              />
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                xs={9}
                className={styles.modalButtonContainer}
              >
                <Grid container xs>
                  <Button
                    onClick={props.handleOpenClose}
                    isCancel={true}
                    text="Отменить"
                    className={styles.modalButton}
                  />
                </Grid>
                <Grid container xs>
                  <Button
                    onClick={onClickHandler}
                    text="Сохранить"
                    className={styles.modalButton}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          <SnackbarOnChange
            openSnack={openSnack}
            setOpenSnack={setOpenSnack}
            isError
            textInSnack="Проверьте обязательные поля (*) на заполненность"
          />
        </Grid>
      </Modal>
      <Snackbar
        open={rejectCreate}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Заполните все необходимые данные
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmCreate}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Данные компании успешно изменены
        </Alert>
      </Snackbar>
    </>
  );
};

export default CompanyEditModal;
