import { makeStyles } from "@material-ui/core/styles";

// @ts-ignore
const useStyles = makeStyles(() => ({
  line: {
    width: "100%",
    backgroundColor: "#CFD1DC",
  },
}));

export default useStyles;
