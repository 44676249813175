export async function getMediaStreamCamera(): Promise<any> {
  try {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: 1280,
        height: 720,
      },
    });
    return {
      stream: mediaStream,
      error: null,
    };
  } catch (err) {
    return {
      stream: null,
      error: err,
    };
  }
}

export async function getMediaStreamMicro(): Promise<any> {
  try {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    return {
      stream: mediaStream,
      error: null,
    };
  } catch (err) {
    return {
      stream: null,
      error: err,
    };
  }
}

export default async function getMediaStream(): Promise<any> {
  try {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: 1280,
        height: 720,
      },
    });
    return {
      stream: mediaStream,
      error: null,
    };
  } catch (err) {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      return {
        stream: mediaStream,
        error: null,
      };
    } catch (err) {
      return {
        stream: null,
        error: err,
      };
    }
  }
}
