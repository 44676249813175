// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Grid,
  OutlinedInput,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import Button from "../../UI/Buttons/OutlinedButton/Button";
import AutoCompleteSearchFieldForCompany from "../../UI/AutoCompleteSearchFields/AutoCompleteSearchFieldForCompany";
import NoneClick from "../../UI/NoneClickableField/NoneClick";
import SnackbarOnChange from "../../UI/Snackbar/Snackbar";
import { ModalImage, ModalImageContainer } from "../../UI/Styles/TS/Style";
import useStyles from "../../UI/Styles/TS/Components/createModalStyles/index";

import imgModalDefault from "../../../assets/img/imgModal.svg";

import {
  CreateProjectMutation,
  GetUrlToUploadMedia,
} from "../graphql/mutations";
import { GET_COMPANIES_QUERY } from "../graphql/query";
import { CompaniesDataQueryTypes } from "../graphql/typing";
import {
  ProjectCreateModalProps,
  ProjectForm,
  RequiredInputs,
} from "./typings";
import { useQuery } from "@apollo/client";

import { set } from "date-fns";

import {
  categoriesOptions,
  defaultOption,
  industrialDirectionsOptions,
  projectBusinessModelsOptions,
  projectInvestmentsStagesOptions,
  projectMainGoalsOptions,
  projectMarketsOptions,
  projectSalesTypesOptions,
  projectStagesOptions,
  projectTechTypesOptions,
  projectTypesOptions,
} from "../../../helpers/constants/categories";



const defaultFormValues: ProjectForm = {
  nameValue: "",
  posterValue: {
    name: "",
    type: "",
    size: 0,
  },
  shortDescriptionValue: "",
  activityTypeValue: defaultOption,
  projectIndustrialDirectionsValue: defaultOption,
  projectTypesValue: defaultOption,
  projectStagesValue: defaultOption,
  projectSiteValue: "",
  projectMarketsValue: defaultOption,
  projectTechTypesValue: defaultOption,
  projectInvestmentsStagesValue: defaultOption,
  projectSalesTypesValue: defaultOption,
  projectBusinessModelsValue: defaultOption,
  projectMainGoalsValue: defaultOption,
  companyIdValue: "",
};

const ProjectCreationModal: (props: ProjectCreateModalProps) => JSX.Element = (
  props: ProjectCreateModalProps
) => {
  const [formValues, setFormValues] = useState<ProjectForm>(defaultFormValues);
  const [isRequiredValid, setIsRequiredValid] = useState<RequiredInputs>({
    nameValue: true,
    companyIdValue: true,
    projectTypeValue: true,
    activityTypeValue: true,
    projectTypesValue: true,
    projectStagesValue: true,
    shortDescriptionValue: true,
    projectIndustrialDirectionsValue: true,
  });

  function handleFormChange(event): void {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  }
  const setCompanyId = (value: string): void => {
    setFormValues({ ...formValues, companyIdValue: value });
  };
  const [imgModal, setImgModal] = useState(imgModalDefault);

  const [openNoneClick, setOpenNoneClick] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const { data: companyData } =
    useQuery<CompaniesDataQueryTypes>(GET_COMPANIES_QUERY);

  const createProjectHandler = CreateProjectMutation();
  const GetUrlToUploadProjectPoster = GetUrlToUploadMedia();


  const onClickHandler: () => Promise<void> = async () => {
    if (
      formValues.nameValue &&
      formValues.shortDescriptionValue &&
      formValues.activityTypeValue.value &&
      (props.ownerId === 0 ? formValues.companyIdValue : props.ownerId) &&
      formValues.projectIndustrialDirectionsValue.value &&
      formValues.projectTypesValue.value &&
      formValues.projectStagesValue.value
    ) {
      setOpenNoneClick(true);
      const newProjectData = {
        name: formValues.nameValue,
        shortDescription: formValues.shortDescriptionValue,
        category: formValues.activityTypeValue.value,
        ownerCompany:
          props.ownerId === 0
            ? Number(
              companyData?.companies.filter(
                company => company.name === formValues.companyIdValue
              )[0].id
            )
            : props.ownerId,
        industrialDirections: formValues.projectIndustrialDirectionsValue.value,
        projectType: formValues.projectTypesValue.value,
        projectStage: formValues.projectStagesValue.value,
        ...(formValues.projectSiteValue && {
          projectSite: "https://" + formValues.projectSiteValue,
        }),
        ...(formValues.projectMarketsValue.value && {
          projectMarket: formValues.projectMarketsValue.value,
        }),
        ...(formValues.projectTechTypesValue.value && {
          technologyType: formValues.projectTechTypesValue.value,
        }),
        ...(formValues.projectInvestmentsStagesValue.value && {
          investmentStage: formValues.projectInvestmentsStagesValue.value,
        }),
        ...(formValues.projectSalesTypesValue.value && {
          salesType: formValues.projectSalesTypesValue.value,
        }),
        ...(formValues.projectBusinessModelsValue.value && {
          businessModel: formValues.projectBusinessModelsValue.value,
        }),
        ...(formValues.projectMainGoalsValue.value && {
          mainGoal: formValues.projectMainGoalsValue.value,
        }),
      };
      const projectId = await createProjectHandler(newProjectData);
      if (formValues.posterValue.size !== 0) {
        const PosterData = {
          entityType: "projectPoster",
          entityId: projectId?.data?.createOneProject?.id,
          fileType: formValues.posterValue.type,
        };
        const uploadUrl = (await GetUrlToUploadProjectPoster(PosterData)).data;
        await fetch(uploadUrl!.createMedia?.signedURL, {
          method: "PUT", // *GET, POST, PUT, DELETE, etc.
          credentials: "include", // include, *same-origin, omit
          headers: {
            "Content-Type": formValues.posterValue.type,
          },
          //@ts-ignore
          body: formValues.posterValue, // body data type must match "Content-Type" header
        });
      }
      props.handleOpenClose();

      if (props.isProfilePage) {
        await props.refetchOnProfilePage!();
      } else {
        await props.refetchOnCompanyPage!();
      }

      setFormValues(defaultFormValues);
      setImgModal(imgModalDefault);
      setOpenNoneClick(false);
    } else {
      const temp = isRequiredValid;
      Object.keys(formValues).forEach(item => {
        if (formValues[item] === "" && typeof formValues[item] !== "object") {
          temp[item] = false;
        } else if (
          formValues[item].value === "" &&
          typeof formValues[item] === "object"
        ) {
          temp[item] = false;
        } else {
          temp[item] = true;
        }
      });
      setIsRequiredValid(temp);
      setOpenSnack(true);
      setTimeout(() => {
        setOpenSnack(false);
      }, 4000);
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick: () => void = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const fileUploaded = event.target.files![0];
    console.log(fileUploaded.type);
    if (fileUploaded.type === "image/jpeg" || fileUploaded.type === "image/png" || fileUploaded.type === "image/jpg") {
      setImgModal(URL.createObjectURL(fileUploaded));
      setFormValues({ ...formValues, posterValue: fileUploaded });
    }
    else event.target.value.files = [];
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });
  const styles = useStyles();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleOpenClose}
        center
        styles={{ modal: { width: isTabletOrMobile ? "" : 800 } }}
      >
        <Grid container direction="column" className={styles.modalContainer}>
          {openNoneClick ? <NoneClick /> : null}
          <Grid item className={styles.modalHeader}>
            Создание проекта
          </Grid>
          {props.ownerId === 0 ? (
            <Grid
              container
              direction="row"
              className={styles.inputContainerGap}
            >
              <Grid
                container
                md={3}
                xs={12}
                alignItems="center"
                className={styles.modalHeaderText}
              >
                Прикрепите компанию*
              </Grid>
              <Grid container xs>
                <AutoCompleteSearchFieldForCompany
                  error={!isRequiredValid.companyIdValue}
                  name="companyIdValue"
                  projectId="100500"
                  value={formValues.companyIdValue}
                  setValue={setCompanyId}
                />
              </Grid>
            </Grid>
          ) : null}
          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Название проекта*
            </Grid>
            <Grid container xs>
              <OutlinedInput
                error={!isRequiredValid.nameValue}
                fullWidth={true}
                name="nameValue"
                defaultValue={formValues.nameValue}
                value={formValues.nameValue}
                placeholder={isTabletOrMobile ? "*" : "Название проекта *"}
                onChange={
                  handleFormChange<
                    React.ChangeEventHandler<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  >
                }
                inputProps={{
                  maxLength: 128,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Краткое описание*
            </Grid>
            <Grid container xs>
              <OutlinedInput
                error={!isRequiredValid.shortDescriptionValue}
                fullWidth={true}
                name="shortDescriptionValue"
                defaultValue={formValues.shortDescriptionValue}
                value={formValues.shortDescriptionValue}
                onChange={
                  handleFormChange<
                    React.ChangeEventHandler<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  >
                }
                placeholder={
                  isTabletOrMobile ? "*" : "Краткое описание проекта *"
                }
                inputProps={{
                  maxLength: 128,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Категория проекта*
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                name="activityTypeValue"
                error={!isRequiredValid.activityTypeValue}
                defaultValue={defaultOption}
                value={formValues.activityTypeValue}
                onChange={handleFormChange<SelectChangeEvent>}
                fullWidth
                size="small"
              >
                <MenuItem disabled value={defaultOption}>{defaultOption.label}</MenuItem>
                {categoriesOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Индустриальное направление проекта*
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                name="projectIndustrialDirectionsValue"
                error={!isRequiredValid.projectIndustrialDirectionsValue}
                defaultValue={defaultOption}
                value={formValues.projectIndustrialDirectionsValue}
                onChange={handleFormChange<SelectChangeEvent>}
                fullWidth
                size="small"
              >
                <MenuItem  disabled value={defaultOption}>{defaultOption.label}</MenuItem>
                {industrialDirectionsOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Тип проекта*
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                defaultValue={defaultOption}
                name="projectTypesValue"
                error={!isRequiredValid.projectTypesValue}
                value={formValues.projectTypesValue}
                onChange={handleFormChange<SelectChangeEvent>}
                fullWidth
                size="small"
              >
                <MenuItem disabled value={defaultOption}>{defaultOption.label}</MenuItem>
                {projectTypesOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Стадия проекта*
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                error={!isRequiredValid.projectStagesValue}
                defaultValue={defaultOption}
                name="projectStagesValue"
                value={formValues.projectStagesValue}
                onChange={handleFormChange<SelectChangeEvent>}
                fullWidth
                size="small"
              >
                <MenuItem disabled value={defaultOption}>{defaultOption.label}</MenuItem>
                {projectStagesOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Рынки проекта
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                defaultValue={defaultOption}
                name="projectMarketsValue"
                value={formValues.projectMarketsValue}
                onChange={handleFormChange<SelectChangeEvent>}
                fullWidth
                size="small"
              >
                <MenuItem value={defaultOption}>{defaultOption.label}</MenuItem>
                {projectMarketsOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Сквозные технологии
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                defaultValue={defaultOption}
                name="projectTechTypesValue"
                value={formValues.projectTechTypesValue}
                onChange={handleFormChange<SelectChangeEvent>}
                fullWidth
                size="small"
              >
                <MenuItem value={defaultOption}>{defaultOption.label}</MenuItem>
                {projectTechTypesOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Стадия инвестирования
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                defaultValue={defaultOption}
                name="projectInvestmentsStagesValue"
                value={formValues.projectInvestmentsStagesValue}
                onChange={handleFormChange<SelectChangeEvent>}
                fullWidth
                size="small"
              >
                <MenuItem value={defaultOption}>{defaultOption.label}</MenuItem>
                {projectInvestmentsStagesOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Уровень продаж
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                name="projectSalesTypesValue"
                value={formValues.projectSalesTypesValue}
                onChange={handleFormChange<SelectChangeEvent>}
                fullWidth
                size="small"
              >
                <MenuItem value={defaultOption}>{defaultOption.label}</MenuItem>
                {projectSalesTypesOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Бизнес-модель
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                defaultValue={defaultOption}
                name="projectBusinessModelsValue"
                value={formValues.projectBusinessModelsValue}
                onChange={handleFormChange<SelectChangeEvent>}
                fullWidth
                size="small"
              >
                <MenuItem value={defaultOption}>{defaultOption.label}</MenuItem>
                {projectBusinessModelsOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Задачи проекта
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                defaultValue={defaultOption}
                name="projectMainGoalsValue"
                value={formValues.projectMainGoalsValue}
                onChange={handleFormChange<SelectChangeEvent>}
                fullWidth
                size="small"
              >
                <MenuItem value={defaultOption}>{defaultOption.label}</MenuItem>
                {projectMainGoalsOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Ссылка на сайт
            </Grid>
            <Grid container xs>
              <OutlinedInput
                name="projectSiteValue"
                fullWidth={true}
                defaultValue={formValues.projectSiteValue}
                value={formValues.projectSiteValue}
                onChange={handleFormChange<React.ChangeEvent<HTMLInputElement>>}
                placeholder={
                  isTabletOrMobile ? "" : "Ссылка на сайт проекта (если есть)"
                }
                inputProps={{
                  maxLength: 512,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid container md={3} xs={12} className={styles.modalHeaderText}>
              Обложка проекта
            </Grid>
            <Grid container xs direction="column" style={{ gap: 15 }}>
              {isTabletOrMobile ? null : (
                <Grid>Выберете изображение для постера вашего проекта</Grid>
              )}
              <Grid container direction="row" style={{ gap: 20 }}>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <ModalImageContainer
                  container
                  justifyContent="center"
                  alignItems="center"
                  onClick={handleClick}
                  md={5}
                  xs={5}
                  isDefault={imgModal === imgModalDefault}
                >
                  <ModalImage
                    src={imgModal}
                    isDefault={imgModal === imgModalDefault}
                  />
                </ModalImageContainer>
                <Grid
                  container
                  xs
                  alignItems="center"
                  className={styles.imgConditionText}
                >
                  JPEG или PNG
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            className={styles.inputContainerGap}
            style={{ marginTop: 30 }}
          >
            <Grid
              container
              xs={12}
              alignItems="center"
              className={styles.imgConditionText}
            >
              Полное описание добавляется отдельно после создания. Вы сможете
              прикрепить все необходимые медиа-файлы.
              <br />
              Поля, отмеченные звездочкой (*), обязательны к заполнению.
            </Grid>
          </Grid>

          {isTabletOrMobile ? (
            <Grid
              container
              direction="column"
              style={{ marginTop: 30, gap: 20 }}
            >
              <Button
                onClick={onClickHandler}
                text="Создать"
                className={styles.modalButton}
              />
              <Button
                onClick={props.handleOpenClose}
                isCancel={true}
                text="Отменить"
                className={styles.modalButton}
              />
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                xs={9}
                className={styles.modalButtonContainer}
              >
                <Grid container xs>
                  <Button
                    onClick={props.handleOpenClose}
                    isCancel={true}
                    text="Отменить"
                    className={styles.modalButton}
                  />
                </Grid>
                <Grid container xs>
                  <Button
                    onClick={onClickHandler}
                    text="Создать"
                    className={styles.modalButton}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <SnackbarOnChange
            openSnack={openSnack}
            setOpenSnack={setOpenSnack}
            isError
            textInSnack="Проверьте обязательные поля (*) на заполненность"
          />
        </Grid>
      </Modal>
    </div>
  );
};

export default ProjectCreationModal;
