// media queries
import { useMediaQuery } from "react-responsive";

export function TabletOrMobile() {
  const tablet = useMediaQuery({ query: "(max-width: 960px)" });
  return tablet;
}

export function Mobile() {
  const mobile = useMediaQuery({ query: "(max-width: 600px)" });
  return mobile;
}

export const innRegexp = /^[0-9]{10}(?:[0-9]{2})?$/;

export const emailRegexp = /^(?=.{1,40}$)[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

export const phoneRegexp = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;