// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import "./style.css";

const Loading: React.FC = () => {
  return <div className="loading" />;
};

export default Loading;
