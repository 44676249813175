import React, { useState, useEffect } from "react";
import { Grid, OutlinedInput } from "@mui/material";
import Button from "../UI/Buttons/OutlinedButton/Button";
import useStyles from "../UI/Styles/TS/Components/createModalStyles/index";
import { useMediaQuery } from "react-responsive";

interface ButtonsForModalProps {
  isDisableFunc: () => boolean | undefined;
  onClickHandlerOK: () => void;
  onClickHandlerClose: () => void;
}
const ButtonsForModal = ({
  isDisableFunc,
  onClickHandlerOK,
  onClickHandlerClose,
}: ButtonsForModalProps) => {
  const styles = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });
  return (
    <Grid
      container
      className={
        isTabletOrMobile
          ? styles.buttonContainerMobile
          : styles.buttonContainerWeb
      }
      direction={`${isTabletOrMobile ? "column" : "row-reverse"}`}
      style={{ paddingLeft: 0 }}
      justifyContent="flex-end"
    >
      <Grid container xs>
        <Button
          onClick={onClickHandlerOK}
          text="Сохранить"
          className={styles.modalButton}
          isDisable={isDisableFunc ? isDisableFunc() : false}
        />
      </Grid>
      <Grid container xs>
        <Button
          onClick={onClickHandlerClose}
          isCancel={true}
          text="Отменить"
          className={styles.modalButton}
        />
      </Grid>
    </Grid>
  );
};
export default ButtonsForModal;
