// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/ban-ts-comment*/

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { Grid, MenuItem, OutlinedInput, Select } from "@mui/material";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

//@ts-ignore
import { ru } from "date-fns/locale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  CreateEventMutation,
  GetUrlToUploadEventPoster,
} from "../../../Pages/Company/graphql/mutation";
import Button from "../../UI/Buttons/OutlinedButton/Button";
import NoneClick from "../../UI/NoneClickableField/NoneClick";
import SnackbarOnChange from "../../UI/Snackbar/Snackbar";

import imgModalDefault from "../../../assets/img/imgModal.svg";
import "../../UI/Styles/CSS/Components/style.css";
import useStyles from "../../UI/Styles/TS/Components/createModalStyles/index";
import { ModalImage, ModalImageContainer } from "../../UI/Styles/TS/Style";

import {
  EventCreateModalProps,
  EventForm,
  EventRequiredInputs,
} from "./typings";

import { categoriesOptions } from "../../../helpers/constants/categories";

const defaultOption = { value: "", label: "Не определена" };
const defaultFormValues: EventForm = {
  nameValue: "",
  descriptionValue: "",
  bodyValue: "",
  categoryValue: defaultOption,
  addressValue: "",
  organizerValue: "",
  themeValue: "",
  eventFormatValue: "",
};

const defaultValidationState = {
  nameValue: true,
  descriptionValue: true,
  categoryValue: true,
  organizerValue: true,
  themeValue: true,
  bodyValue: true,
  addressValue: true,
  eventFormatValue: true,
};

const CreateEventModal: (props: EventCreateModalProps) => JSX.Element = (
  props: EventCreateModalProps
) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });
  const getUrlToUploadEventPosterHandler = GetUrlToUploadEventPoster();
  const [formValues, setFormValues] = useState<EventForm>(defaultFormValues);

  const [posterValue, setPosterValue] = useState<{
    name: string;
    size: number;
    type: string;
  }>({
    name: "",
    type: "",
    size: 0,
  });
  const [dateValue, setDateValue] = useState(new Date());
  const [imgModal, setImgModal] = useState(imgModalDefault);
  const handleDateChange = (value: Date): void => {
    if (value.getTime() > Date.now()) setDateValue(value);
    else setDateValue(new Date());
  };

  const [openNoneClick, setOpenNoneClick] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openFormatSnack, setOpenFormatSnack] = useState(false);

  const [isRequiredValid, setIsRequiredValid] = useState<EventRequiredInputs>(
    defaultValidationState
  );

  function handleFormChange<EventType>(event: EventType): void {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  }

  const createEventHandler = CreateEventMutation();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const createEvent = async () => {
    setIsRequiredValid(defaultValidationState);
    setOpenNoneClick(true);
    const newEventData = {
      name: formValues.nameValue,
      category: formValues.categoryValue.value,
      description: formValues.bodyValue,
      shortDescription: formValues.descriptionValue,
      date: dateValue,
      organizer: formValues.organizerValue,
      address: formValues.addressValue,
      theme: formValues.themeValue,
      ...(formValues.eventFormatValue && {
        format: formValues.eventFormatValue,
      }),
    };

    const eventId = await createEventHandler(newEventData);
    if (posterValue.size !== 0) {
      const PosterData = {
        entityType: "eventPoster",
        entityId: eventId?.data?.createOneEvent?.id,
        fileType: posterValue.type,
      };
      const uploadUrl = (await getUrlToUploadEventPosterHandler(PosterData))
        .data;
      await fetch(uploadUrl!.createMedia?.signedURL, {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        credentials: "include", // include, *same-origin, omit
        headers: {
          "Content-Type": posterValue.type,
        },
        //@ts-ignore
        body: posterValue, // body data type must match "Content-Type" header
      });
    }
    setFormValues(defaultFormValues);
    setDateValue(new Date());
    setImgModal(imgModalDefault);
    setOpenNoneClick(false);
    setPosterValue({
      name: "",
      type: "",
      size: 0,
    });
    props.handleOpenClose();
    await props.refetch();
  };

  const validate = (text: string): boolean => {
    const res = /.*[а-яА-Яa-zA-Z].*/.test(text) && text !== "";
    setOpenFormatSnack(!res);
    return res;
  };

  const onClickHandler: () => Promise<void> = async () => {
    if (
      validate(formValues.nameValue) &&
      formValues.categoryValue.value &&
      formValues.bodyValue &&
      validate(formValues.descriptionValue) &&
      dateValue &&
      formValues.organizerValue &&
      formValues.addressValue &&
      formValues.themeValue
    ) {
      setIsRequiredValid({
        nameValue: true,
        descriptionValue: true,
        categoryValue: true,
        organizerValue: true,
        themeValue: true,
        bodyValue: true,
        addressValue: true,
        eventFormatValue: true,
      });
      await createEvent();
    } else {
      const temp: EventRequiredInputs = isRequiredValid;
      Object.keys(formValues).forEach(item => {
        if (formValues[item] === "" && typeof formValues[item] !== "object") {
          temp[item] = false;
        } else if (
          formValues[item].value === "" &&
          typeof formValues[item] === "object"
        ) {
          temp[item] = false;
        } else {
          temp[item] = true;
        }
      });
      if (!validate(formValues.descriptionValue)) temp.descriptionValue = false;
      if (!validate(formValues.nameValue)) temp.nameValue = false;
      setIsRequiredValid(temp);
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    if (openSnack) {
      setTimeout(() => {
        console.log(2);
        setOpenSnack(false);
        setIsRequiredValid({
          nameValue: true,
          descriptionValue: true,
          categoryValue: true,
          organizerValue: true,
          themeValue: true,
          bodyValue: true,
          addressValue: true,
          eventFormatValue: true,
        });
      }, 4000);
    }
  }, [openSnack]);


  const handleClick: () => void = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const fileUploaded = event.target.files![0];
    if (
      fileUploaded.type === "image/jpeg" ||
      fileUploaded.type === "image/png" ||
      fileUploaded.type === "image/jpg"
    ) {
      setImgModal(URL.createObjectURL(fileUploaded));
      setPosterValue(fileUploaded);
    } else event.target.value.files = [];
  };

  useEffect(() => {
    setIsRequiredValid({
      nameValue: true,
      descriptionValue: true,
      categoryValue: true,
      organizerValue: true,
      themeValue: true,
      bodyValue: true,
      addressValue: true,
      eventFormatValue: true,
    });
  }, [props.open]);

  const styles = useStyles();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleOpenClose}
        center
        styles={{ modal: { width: isTabletOrMobile ? "" : 800 } }}
      >
        <Grid container direction="column" className={styles.modalContainer}>
          {openNoneClick ? <NoneClick /> : null}
          <Grid item className={styles.modalHeader}>
            Создание Мероприятия
          </Grid>
          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Заголовок
            </Grid>
            <Grid container xs>
              <OutlinedInput
                error={!isRequiredValid.nameValue}
                fullWidth={true}
                name="nameValue"
                defaultValue={formValues.nameValue}
                value={formValues.nameValue}
                placeholder={isTabletOrMobile ? "*" : "Заголовок мероприятия *"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleFormChange<React.ChangeEvent<HTMLInputElement>>(e)
                }
                inputProps={{
                  maxLength: 96,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Краткое описание
            </Grid>
            <Grid container xs>
              <OutlinedInput
                error={!isRequiredValid.descriptionValue}
                fullWidth={true}
                name="descriptionValue"
                defaultValue={formValues.descriptionValue}
                value={formValues.descriptionValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleFormChange<React.ChangeEvent<HTMLInputElement>>(e)
                }
                placeholder={
                  isTabletOrMobile ? "*" : "Краткое описание мероприятия *"
                }
                inputProps={{
                  maxLength: 128,
                }}
                required
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Дата
            </Grid>
            <Grid container md={5} xs={12}>
              <DatePicker
                dateFormat="dd.MM.yyyy HH:mm"
                locale={ru}
                minDate={new Date()}
                showTimeSelect
                onChange={handleDateChange}
                timeIntervals={15}
                selected={dateValue}
                className={[styles.dateTime, styles.dateTimeFont]}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Категория*
            </Grid>
            <Grid container md={5} xs={12}>
              <Select
                name="activityTypeValue"
                error={!isRequiredValid.categoryValue}
                defaultValue={defaultOption}
                value={formValues.categoryValue}
                onChange={(e: any): void => {
                  setFormValues({
                    ...formValues,
                    categoryValue: e.target.value,
                  });
                }}
                fullWidth
                size="small"
              >
                <MenuItem disabled value={defaultOption}>
                  {defaultOption.label}
                </MenuItem>
                {categoriesOptions.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid container md={3} xs={12} className={styles.modalHeaderText}>
              Обложка
            </Grid>
            <Grid container xs direction="column" style={{ gap: 15 }}>
              {isTabletOrMobile ? null : (
                <Grid>Выберете изображение для обложки мероприятия</Grid>
              )}
              <Grid container direction="row" style={{ gap: 20 }}>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                  accept=".png, .jpg, .jpeg"
                />
                <ModalImageContainer
                  container
                  justifyContent="center"
                  alignItems="center"
                  onClick={handleClick}
                  md={5}
                  xs={5}
                  isDefault={imgModal === imgModalDefault}
                >
                  <ModalImage
                    src={imgModal}
                    isDefault={imgModal === imgModalDefault}
                  />
                </ModalImageContainer>
                <Grid
                  container
                  xs
                  alignItems="center"
                  className={styles.imgConditionText}
                >
                  JPEG или PNG
                  <br />
                  930x385px
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              style={{ paddingTop: isTabletOrMobile ? 0 : 10 }}
              className={styles.modalHeaderText}
            >
              Описание
            </Grid>
            <Grid item xs>
              <OutlinedInput
                name="bodyValue"
                fullWidth
                error={!isRequiredValid.bodyValue}
                defaultValue={formValues.bodyValue}
                value={formValues.bodyValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleFormChange<React.ChangeEvent<HTMLInputElement>>(e)
                }
                placeholder={isTabletOrMobile ? "*" : "Описание мероприятия *"}
                multiline
                maxRows={7}
                color="primary"
                inputProps={{
                  maxLength: 8128,
                  overflow: "auto",
                }}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Организатор
            </Grid>
            <Grid container xs>
              <OutlinedInput
                name="organizerValue"
                fullWidth={true}
                error={!isRequiredValid.organizerValue}
                defaultValue={formValues.organizerValue}
                value={formValues.organizerValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleFormChange<React.ChangeEvent<HTMLInputElement>>(e)
                }
                placeholder={
                  isTabletOrMobile ? "*" : "Организатор мероприятия *"
                }
                inputProps={{
                  maxLength: 56,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Тематика
            </Grid>
            <Grid container xs>
              <OutlinedInput
                name="themeValue"
                fullWidth={true}
                error={!isRequiredValid.themeValue}
                defaultValue={formValues.themeValue}
                value={formValues.themeValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleFormChange<React.ChangeEvent<HTMLInputElement>>(e)
                }
                placeholder={isTabletOrMobile ? "*" : "Тематика мероприятия *"}
                inputProps={{
                  maxLength: 62,
                }}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Адрес проведения
            </Grid>
            <Grid container xs>
              <OutlinedInput
                name="addressValue"
                fullWidth={true}
                error={!isRequiredValid.addressValue}
                defaultValue={formValues.addressValue}
                value={formValues.addressValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleFormChange<React.ChangeEvent<HTMLInputElement>>(e)
                }
                placeholder={isTabletOrMobile ? "*" : "Адрес мероприятия *"}
                inputProps={{
                  maxLength: 96,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              Формат
            </Grid>
            <Grid container xs>
              <OutlinedInput
                error={!isRequiredValid.eventFormatValue}
                name="eventFormatValue"
                fullWidth={true}
                defaultValue={formValues.eventFormatValue}
                value={formValues.eventFormatValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleFormChange<React.ChangeEvent<HTMLInputElement>>(e)
                }
                placeholder={isTabletOrMobile ? "" : "Формат мероприятия"}
                inputProps={{
                  maxLength: 36,
                }}
                size="small"
              />
            </Grid>
          </Grid>

          <p>
            Ведение трансляции ограничено в целях безопасности и возможно из
            локальной сети Донского государственного технического университета.
            В случае необходимости проведения трансляции, обратитесь по
            контактным данным, указанным на портале.
          </p>
          {isTabletOrMobile ? (
            <Grid
              container
              direction="column"
              style={{ marginTop: 30, gap: 20 }}
            >
              <Button
                onClick={onClickHandler}
                text="Создать"
                className={styles.modalButton}
              />
              <Button
                onClick={props.handleOpenClose}
                isCancel={true}
                text="Отменить"
                className={styles.modalButton}
              />
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                xs={9}
                className={styles.modalButtonContainer}
                style={{ marginTop: 20 }}
              >
                <Grid container xs>
                  <Button
                    onClick={props.handleOpenClose}
                    isCancel={true}
                    text="Отменить"
                    className={styles.modalButton}
                  />
                </Grid>

                <Grid container xs>
                  <Button
                    onClick={onClickHandler}
                    text="Создать"
                    className={styles.modalButton}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <SnackbarOnChange
            openSnack={openSnack}
            setOpenSnack={setOpenSnack}
            isError
            textInSnack="Проверьте обязательные поля (*) на заполненность"
          />
          <SnackbarOnChange
            openSnack={openFormatSnack}
            setOpenSnack={setOpenFormatSnack}
            isError
            textInSnack="Поля не могут состоять только из цифр и спец. символов"
          />
        </Grid>
      </Modal>
    </div>
  );
};

export default CreateEventModal;
