// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/ban-ts-comment*/
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Grid, OutlinedInput } from "@mui/material";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useQuery } from "@apollo/client";
import NoneClick from "../../UI/NoneClickableField/NoneClick";
import SnackbarOnChange from "../../UI/Snackbar/Snackbar";
import useStyles from "../../UI/Styles/TS/Components/createModalStyles/index";
import { ModalImage, ModalImageContainer } from "../../UI/Styles/TS/Style";

import imgModalDefault from "../../../assets/img/imgModal.svg";

import { GET_PROFILE_QUERY } from "./graphql/query";
import { ProfileTypes, ProfileEditModalProps } from "./graphql/typings";
import {
  UpdateUserDataMutation,
  GetUrlToUploadProfileAvatar,
} from "./graphql/mutation";
import { validateName } from "../../../utils/validateName";
import { validateNoLatin } from "../../../utils/validateNoLatin";
import { isValidCity } from "../../../utils/cityValidator";
import ButtonsForModal from "../../All/ButtonsForModal";

const ProfileEditModal: (props: ProfileEditModalProps) => JSX.Element = (
  props: ProfileEditModalProps
) => {
  const styles = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });

  const { data, loading, refetch } = useQuery<{ user: ProfileTypes }>(
    GET_PROFILE_QUERY,
    {
      variables: { id: props.userId },
    }
  );

  const updateProfileHandler = UpdateUserDataMutation();
  const UrlToUploadAvatarHandler = GetUrlToUploadProfileAvatar();

  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [bioValue, setBioValue] = useState("");
  const [shortDescriptionValue, setShortDescriptionValue] = useState("");
  const [cityValue, setCityValue] = useState("");

  const [isFNameValid, setIsFNameValid] = useState(true);
  const [isLNameValid, setIsLNameValid] = useState(true);
  const [isShortDescriptionValueValid, setIsShortDescriptionValueValid] =
    useState(true);
  const [isBioValid, setIsBioValid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);

  const [avatarValue, setAvatarValue] = useState<{
    name: string;
    size: number;
    type: string;
  }>({
    name: "",
    type: "",
    size: 0,
  });
  const [imgModal, setImgModal] = useState(imgModalDefault);

  const [openSnack, setOpenSnack] = useState(false);
  const [openNoneClick, setOpenNoneClick] = useState(false);

  const onClickHandler: () => Promise<void> = async () => {
    if (firstNameValue && lastNameValue) {
      setOpenNoneClick(true);

      const newProfileData = {
        firstname: firstNameValue,
        lastname: lastNameValue,
        shortDescription: shortDescriptionValue,
        bio: bioValue,
        city: cityValue,
        userId: props.userId,
      };
      await updateProfileHandler(newProfileData);
      if (avatarValue.size !== 0) {
        const AvatarData = {
          entityType: "userAvatar",
          entityId: props.userId,
          fileType: avatarValue.type,
        };
        const uploadUrl = await UrlToUploadAvatarHandler(AvatarData);
        await fetch(uploadUrl.data!.createMedia?.signedURL, {
          method: "PUT", // *GET, POST, PUT, DELETE, etc.
          credentials: "include", // include, *same-origin, omit
          headers: {
            "Content-Type": avatarValue.type,
          },
          //@ts-ignore
          body: avatarValue, // body data type must match "Content-Type" header
        });
      }
      props.handleOpenClose();
      await refetch();
      setOpenNoneClick(false);
    } else {
      setOpenSnack(true);
      setTimeout(() => setOpenSnack(false), 4000);
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick: () => void = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const fileUploaded = event.target.files![0];
    setImgModal(URL.createObjectURL(fileUploaded));
    setAvatarValue(fileUploaded);
  };

  const dataForField = [
    {
      title: "Редактировать имя*",
      defaultValue: firstNameValue,
      value: firstNameValue,
      placeholder: "Ваше имя*",
      onChange: setFirstNameValue,
      validateValue: isFNameValid,
      setValidateValue: setIsFNameValid,
      validateFunc: validateName,
    },
    {
      title: "Редактировать фамилию*",
      defaultValue: lastNameValue,
      value: lastNameValue,
      placeholder: "Ваша фамилия*",
      onChange: setLastNameValue,
      validateValue: isLNameValid,
      setValidateValue: setIsLNameValid,
      validateFunc: validateName,
    },
    {
      title: "Редактировать специализацию",
      defaultValue: shortDescriptionValue,
      value: shortDescriptionValue,
      placeholder: "Ваша специализация",
      onChange: setShortDescriptionValue,
      validateValue: isShortDescriptionValueValid,
      setValidateValue: setIsShortDescriptionValueValid,
      validateFunc: validateNoLatin,
    },
    {
      title: "Редактировать биографию",
      defaultValue: bioValue,
      value: bioValue,
      placeholder: "Ваша биография",
      onChange: setBioValue,
      validateValue: isBioValid,
      setValidateValue: setIsBioValid,
      validateFunc: validateNoLatin,
    },
    {
      title: "Редактировать город",
      defaultValue: cityValue,
      value: cityValue,
      placeholder: "Ваш город",
      onChange: setCityValue,
      validateValue: isCityValid,
      setValidateValue: setIsCityValid,
      validateFunc: isValidCity,
    },
  ];

  useEffect(() => {
    if (!loading && data) {
      setFirstNameValue(data.user.firstname);
      setLastNameValue(data.user.lastname);
      setShortDescriptionValue(data.user.shortDescription);
      setBioValue(data.user.bio);
      setCityValue(data.user.city);
      setImgModal(data.user.avatar.link);
    }
    dataForField.map(item => {
      item.setValidateValue(item.validateFunc(item.value));
    });
  }, [loading, data, props.open]);

  const checkValidate = () => {
    const arr = dataForField.filter(
      item => item.validateValue !== undefined && item.validateValue !== true
    );
    return arr.length > 0 ? true : false;
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleOpenClose}
      center
      styles={{ modal: { width: isTabletOrMobile ? "" : 800 } }}
    >
      <Grid container direction="column" className={styles.modalContainer}>
        {openNoneClick ? <NoneClick /> : null}

        <Grid item className={styles.modalHeader}>
          Редактирование профиля
        </Grid>
        {dataForField.map((item, index) => (
          <Grid
            container
            direction="row"
            className={styles.inputContainerGap}
            key={index}
          >
            <Grid
              container
              md={3}
              xs={12}
              alignItems="center"
              className={styles.modalHeaderText}
            >
              {item.title}
            </Grid>
            <Grid container xs>
              <OutlinedInput
                error={!item.validateValue}
                fullWidth={true}
                defaultValue={item.defaultValue}
                value={item.value}
                placeholder={item.placeholder}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  item.setValidateValue(item.validateFunc(e.target.value));
                  item.onChange(e.target.value);
                }}
                inputProps={{
                  maxLength: 128,
                }}
                size="small"
              />
            </Grid>
          </Grid>
        ))}

        <Grid container direction="row" className={styles.inputContainerGap}>
          <Grid container md={3} xs={12} className={styles.modalHeaderText}>
            Изменить фотографию
          </Grid>
          <Grid container xs direction="column" style={{ gap: 15 }}>
            {isTabletOrMobile ? null : <Grid>Выберете фотографию</Grid>}
            <Grid container direction="row" style={{ gap: 20 }}>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <ModalImageContainer
                container
                justifyContent="center"
                alignItems="center"
                onClick={handleClick}
                md={7}
                xs={5}
                isDefault={imgModal === imgModalDefault}
              >
                <ModalImage
                  src={imgModal}
                  isDefault={imgModal === imgModalDefault}
                />
              </ModalImageContainer>
              <Grid
                container
                xs
                alignItems="center"
                className={styles.imgConditionText}
              >
                JPEG или PNG
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ButtonsForModal
          isDisableFunc={checkValidate}
          onClickHandlerOK={onClickHandler}
          onClickHandlerClose={props.handleOpenClose}
        />

        <SnackbarOnChange
          openSnack={openSnack}
          setOpenSnack={setOpenSnack}
          isError
          textInSnack="Проверьте обязательные поля (*) на заполненность"
        />
      </Grid>
    </Modal>
  );
};

export default ProfileEditModal;
