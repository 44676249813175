import React from "react";

import { Link, Typography } from "@material-ui/core";

export const Copyright = (): JSX.Element => {
    return (
      <Typography variant='body2' color='textSecondary' align='center'>
        {"Права защищены © "}
        <Link color='inherit' href='#'>
          NewVision
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  };