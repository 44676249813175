/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Grid, OutlinedInput } from "@mui/material";

import { useQuery } from "@apollo/client";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@mui/material/TextField";
import NoneClick from "../../UI/NoneClickableField/NoneClick";
import SnackbarOnChange from "../../UI/Snackbar/Snackbar";
import Button from "../../UI/Buttons/OutlinedButton/Button";

import { UpdateContactsMutation } from "../graphql/mutation";
import { EditContactsModalProps, ContactTypes } from "./graphql/typing";
import { GET_CONTACTS_QUERY } from "./graphql/query";

import useStyles from "../../UI/Styles/TS/Components/createModalStyles/index";
import { emailRegexp, phoneRegexp } from "../../../helpers/constants/constants";
import { error } from "console";
import ButtonsForModal from "../../All/ButtonsForModal";
import { validatePhone } from "../../../utils/validatePhone";
import { validateEmail } from "../../../utils/validateEmail";

const defaultContacts = {
  emailValue: "",
  phoneValue: "",
  addressValue: "",
};

const EditContactsModal: (props: EditContactsModalProps) => JSX.Element = (
  props: EditContactsModalProps
) => {
  const styles = useStyles();

  const { data, loading } = useQuery<{ contact: ContactTypes }>(
    GET_CONTACTS_QUERY,
    {
      variables: { id: Number(props.contactId) },
    }
  );

  const [formValues, setFormValues] = useState<ContactsForm>(defaultContacts);

  const [openNoneClick, setOpenNoneClick] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const updateContactsHandler = UpdateContactsMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormValues({
      ...formValues,
      [e.target.name]: [...formValues[e.target.name], e.target.value],
    });
  };

  useEffect(() => {
    if (!loading && data) {
      setFormValues({
        emailValue: data.contact.emails,
        phoneValue: data.contact.phones,
        addressValue: data.contact.adresses,
      });
    }
  }, [loading, data]);

  const checkFields = () => {
    return (
      formValues.phoneValue.length === 0 ||
      formValues.emailValue.length === 0 ||
      formValues.addressValue.length === 0
    );
  };

  const onClickHandler: () => Promise<void> = async () => {
    // setOpenNoneClick(true);
    const newContactData = {
      emails: formValues.emailValue,
      adresses: formValues.addressValue,
      phones: formValues.phoneValue,
      contactId: props.contactId,
    };
    await updateContactsHandler(newContactData)
      .then(() => {
        props.handleOpenClose();
      })
      .catch(err => {
        console.error(err);
      });
    await props.refetch();
    setOpenNoneClick(false);
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 899px)" });

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleOpenClose}
        center
        styles={{ modal: { width: isTabletOrMobile ? "" : 800 } }}
      >
        <Grid container direction="column" className={styles.modalContainer}>
          {openNoneClick ? <NoneClick /> : null}
          <Grid item className={styles.modalHeader}>
            Редактирование контактов
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            Для сохранения данных после ввода необходимо нажать Enter
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              className={styles.modalHeaderText}
              style={{ paddingTop: isTabletOrMobile ? 0 : 10 }}
            >
              Номера телефонов
            </Grid>
            <Grid item xs>
              <Autocomplete
                disableClearable
                multiple
                isOptionEqualToValue={(option, value) => {
                  return formValues.phoneValue.includes(option);
                }}
                id="tags-filled"
                name="phoneValue"
                options={[]}
                freeSolo
                defaultValue={formValues.phoneValue}
                value={formValues.phoneValue}
                placeholder="Нажмите Enter для каждого из номеров телефонов"
                onChange={e => {
                  if (validatePhone(e.target.value)) {
                    handleChange(e);
                  }
                }}
                renderTags={(value: string[], getTagProps) =>
                  formValues.phoneValue.map((option: string, index: number) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      variant="outlined"
                      label={option}
                      onDelete={() => {
                        setFormValues({
                          ...formValues,
                          phoneValue: [...formValues.phoneValue].filter(
                            it => it != option
                          ),
                        });
                      }}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    name="phoneValue"
                    {...params}
                    variant="standard"
                    label="Номера телефонов"
                    placeholder={
                      formValues.phoneValue.length == 0 ? "+79185399910" : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              className={styles.modalHeaderText}
              style={{ paddingTop: isTabletOrMobile ? 0 : 10 }}
            >
              Электронные адреса
            </Grid>
            <Grid item xs>
              <Autocomplete
                multiple
                disableClearable
                id="tags-filled"
                name="emailValue"
                options={[]}
                defaultValue={formValues.emailValue}
                value={formValues.emailValue}
                freeSolo
                placeholder="Нажмите Enter для каждого из электронных адресов"
                onChange={e => {
                  if (validateEmail(e.target.value)) {
                    handleChange(e);
                  }
                }}
                isOptionEqualToValue={(option, value) => {
                  return formValues.emailValue.includes(option);
                }}
                renderTags={(value: string[], getTagProps) =>
                  formValues.emailValue.map((option: string, index: number) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      variant="outlined"
                      label={option}
                      onDelete={() => {
                        setFormValues({
                          ...formValues,
                          emailValue: [...formValues.emailValue].filter(
                            it => it != option
                          ),
                        });
                      }}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    name="emailValue"
                    {...params}
                    variant="standard"
                    label="Email"
                    placeholder={
                      formValues.emailValue.length == 0
                        ? "info@magicamedia.com"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" className={styles.inputContainerGap}>
            <Grid
              container
              md={3}
              xs={12}
              className={styles.modalHeaderText}
              style={{ paddingTop: isTabletOrMobile ? 0 : 10 }}
            >
              Адреса
            </Grid>
            <Grid item xs>
              <Autocomplete
                multiple
                disableClearable
                id="tags-filled"
                name="addressValue"
                options={[]}
                defaultValue={formValues.addressValue}
                value={formValues.addressValue}
                freeSolo
                placeholder="Нажмите Enter для каждого из адресов"
                onChange={e => {
                  handleChange(e);
                }}
                isOptionEqualToValue={(option, value) => {
                  return formValues.addressValue.includes(option);
                }}
                slotProps={{ clearIndicator: { type: "button" } }}
                renderTags={(value: string[], getTagProps) =>
                  formValues.addressValue.map(
                    (option: string, index: number) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={index}
                        variant="outlined"
                        label={option}
                        onDelete={() => {
                          setFormValues({
                            ...formValues,
                            addressValue: [...formValues.addressValue].filter(
                              it => it != option
                            ),
                          });
                        }}
                      />
                    )
                  )
                }
                renderInput={params => (
                  <TextField
                    name="addressValue"
                    {...params}
                    variant="standard"
                    label="Адреса"
                    placeholder={
                      formValues.addressValue.length == 0
                        ? "пр. Михаила Нагибина, 3а, Ростов-на-Дону"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <ButtonsForModal
            isDisableFunc={checkFields}
            onClickHandlerOK={onClickHandler}
            onClickHandlerClose={props.handleOpenClose}
          />

          <SnackbarOnChange
            openSnack={openSnack}
            setOpenSnack={setOpenSnack}
            isError
            textInSnack="Проверьте обязательные поля (*) на заполненность"
          />
        </Grid>
      </Modal>
    </div>
  );
};

export default EditContactsModal;
