import { makeStyles } from "@material-ui/core/styles";

// @ts-ignore
const useStyles = makeStyles(() => ({
  colorLink: {
    color: "#FF5631",
  },
}));

export default useStyles;
